import "./App.css";
import "./setupTalGel";
import { GelThemeProvider } from "@tal-gel/theming";
import { GelScreenDetector } from "@tal-gel/components";
import { BrowserRouter } from "react-router-dom";
import { RoutingModule } from "./routes";
import { UserContextProvider } from "./common/usercontext/user.context";
import { UserSessionContextProvider } from "./common/usercontext/usersession.context";

function App() {
  return (
    <GelThemeProvider mode={process.env.NODE_ENV}>
      <UserContextProvider>
        <UserSessionContextProvider>
          <GelScreenDetector>
            <div>
              <BrowserRouter>
                <RoutingModule></RoutingModule>
              </BrowserRouter>
            </div>
          </GelScreenDetector>
        </UserSessionContextProvider>
      </UserContextProvider>
    </GelThemeProvider>
  );
}

export default App;
