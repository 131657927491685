import {
  GelPageLayout,
  GelPageContent,
  GelPageHeader,
  GelHeading3,
  GelTextInput,
  GelForm,
  GelFormField,
  useGelFormData,
  GelPostcodeInput,
  GelColumnLayout,
  GelRow,
  GelCol,
  GelSelect,
  GelButton,
  GelTooltip,
  GelHeading6,
  GelParagraph,
  GelSpinner,
  GelModal,
  GelEmailInput,
  GelPhoneNumberInput,
  GelBoxLayout,
} from "@tal-gel/components";
import { useOktaAuth } from "@okta/okta-react";
import { getGelTokens } from "@tal-gel/theming";
import styled from "@emotion/styled";
import { useState } from "react";
import { OrganisationForm } from "../../models/OrganisationForm";
import { BranchForm } from "../../models/BranchForm";
import { UserForm } from "../../models/UserForm";
import axios from "axios";
import OrgSubmitStatus from "./OrgSubmitStatus";
import { useNavigate } from "react-router-dom";
import AccountHeader from "../../components/layout/AccountHeader";
const Organisation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { oktaAuth, authState } = useOktaAuth();
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const { formData, onFormDataChange, shouldValidateAfterInit } =
    useGelFormData({
      organisationName: "",
      branchName: "",
      postalCode: "",
      state: "",
      emailDomainsUsed: "",
      firstName: "",
      lastName: "",
      emailAddress: "",
      mobilePhoneNumber: "",
    });
  var operation = window.location.href
    .replace(window.location.origin, "")
    .replace("/", "");
  console.log(authState?.accessToken);
  if (authState?.accessToken) navigate("/");
  const backtoLogin = () => {
    navigate("/login");
  };

  const onModalClose = () => {
    setShowSubmitModal(false);
    backtoLogin();
  };

  const submitOrganisation = async () => {
    setLoading(true);
    const branchForm: BranchForm = {
      branchName: formData.branchName,
      postCode: formData.postalCode,
      state: formData.state,
    };
    const userForm: UserForm = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.emailAddress,
      mobilePhone: formData.mobilePhoneNumber,
      organization: "",
      branchId: "",
      userType: "",
      roleId: "DB69DAA7-224D-4D81-8ECC-F9DFD5A0A4B7",
    };
    const organisationForm: OrganisationForm = {
      organisationName: formData.organisationName,
      branchForm: branchForm,
      userForm: userForm,
      domainName: formData.emailDomainsUsed,
    };
    const registerOrganisationQuery = `
    mutation RegisterOrganisation($organisationForm: OrganisationFormInput!) {
      registerOrganisation(organisationForm: $organisationForm) {
        organisationId
      }
  }`;
    const variables = {
      organisationForm: organisationForm,
    };
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/graphql/`, {
        query: registerOrganisationQuery,
        variables,
      });
    } catch (error: any) {
      // string contains

      if (
        error?.response?.data?.errors &&
        Array.isArray(error.response.data.errors) &&
        error.response.data.errors.length > 0 &&
        error.response.data.errors[0].message.indexOf("User Already Exists") !==
          -1
      ) {
        navigate("/duplicateUserAccessController");
      } else if (
        error?.response?.data?.errors &&
        Array.isArray(error.response.data.errors) &&
        error.response.data.errors.length > 0 &&
        error.response.data.errors[0].message.indexOf(
          "Organisation Already Exists"
        ) !== -1
      ) {
        navigate("/duplicateRegisterationError");
      } else navigate("/registerOrganisationError");
    }
    setShowSubmitModal(true);
  };

  return (
    <>
      <GelPageLayout>
        <GelPageHeader>
          <AccountHeader />
        </GelPageHeader>
        <GelPageContent>
          <StyledOrganisationContainer>
            <GelForm
              labelAtTop
              disableOnSubmit
              onSubmit={submitOrganisation}
              shouldValidateAfterInit={shouldValidateAfterInit}
            >
              {loading && <GelSpinner large overlay />}
              <GelHeading3>Create Your Organisation</GelHeading3>
              <br />
              <GelColumnLayout
                style={{
                  backgroundColor:
                    getGelTokens().global.themeColorBackgroundDefault,
                  width: "60%",
                  align: "center",
                }}
              >
                <GelRow>
                  <GelCol>
                    <GelFormField label="Organisation Name">
                      <GelTextInput
                        name="organisationName"
                        data-testid="organisationName"
                        maxLength={120}
                        onChange={onFormDataChange}
                        value={formData.organisationName}
                        required
                        errorMsg={{
                          required: "Please enter Organisation Name",
                        }}
                      ></GelTextInput>
                    </GelFormField>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelFormField label="Branch Name">
                      <GelTextInput
                        name="branchName"
                        data-testid="branchName"
                        maxLength={120}
                        onChange={onFormDataChange}
                        value={formData.branchName}
                        required
                        errorMsg={{
                          required: "Please enter Branch Name",
                        }}
                      ></GelTextInput>
                    </GelFormField>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelColumnLayout distribution="1/2 1/2">
                      <GelFormField label="Postcode">
                        <GelPostcodeInput
                          name="postalCode"
                          value={formData.postalCode}
                          onChange={onFormDataChange}
                          required
                          errorMsg={{
                            required: "Please enter a Postcode",
                          }}
                        />
                      </GelFormField>
                      <GelFormField label="State">
                        <GelSelect
                          name="state"
                          value={formData.state}
                          onChange={onFormDataChange}
                          placeholder="Please select"
                          options={[
                            { label: "ACT", value: "ACT" },
                            { label: "NSW", value: "NSW" },
                            { label: "NT", value: "NT" },
                            { label: "QLD", value: "QLD" },
                            { label: "SA", value: "SA" },
                            { label: "TAS", value: "TAS" },
                            { label: "VIC", value: "VIC" },
                            { label: "WA", value: "WA" },
                          ]}
                          required
                          errorMsg={{
                            required: "Please select a State",
                          }}
                        />
                      </GelFormField>
                    </GelColumnLayout>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelFormField label="Email Domains used">
                      <GelTextInput
                        name="emailDomainsUsed"
                        value={formData.emailDomainsUsed}
                        onChange={onFormDataChange}
                        required
                        errorMsg={{
                          required: "Please enter a Domain",
                        }}
                      />
                    </GelFormField>
                  </GelCol>
                </GelRow>
              </GelColumnLayout>
              <br />
              <br />
              <div style={{ display: "flex", marginTop: "10px" }}>
                <GelHeading3>Create Administrative User</GelHeading3>
                <GelTooltip topRight>
                  Once approved you can add as many additional users as you
                  require
                </GelTooltip>
              </div>
              <br />
              <GelColumnLayout
                style={{
                  backgroundColor:
                    getGelTokens().global.themeColorBackgroundDefault,
                  width: "60%",
                }}
              >
                <GelRow>
                  <GelCol>
                    <GelFormField label="First Name">
                      <GelTextInput
                        name="firstName"
                        data-testid="firstName"
                        maxLength={120}
                        onChange={onFormDataChange}
                        value={formData.firstName}
                        required
                        regex={"^[a-zA-Z-'\\s]*$"}
                        errorMsg={{
                          required: "Please enter First Name",
                          regex:
                            "Please enter letters, spaces, dashes (-) and apostrophes (') only",
                        }}
                      ></GelTextInput>
                    </GelFormField>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelFormField label="Last Name">
                      <GelTextInput
                        name="lastName"
                        data-testid="lastName"
                        maxLength={120}
                        regex={"^[a-zA-Z-'\\s]*$"}
                        onChange={onFormDataChange}
                        value={formData.lastName}
                        required
                        errorMsg={{
                          required: "Please enter Last Name",
                          regex:
                            "Please enter letters, spaces, dashes (-) and apostrophes (') only",
                        }}
                      ></GelTextInput>
                    </GelFormField>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelFormField label="Email Address">
                      <GelEmailInput
                        name="emailAddress"
                        data-testid="emailAddress"
                        maxLength={120}
                        onChange={onFormDataChange}
                        value={formData.emailAddress}
                        required
                        errorMsg={{
                          required: "Please enter Email Address",
                          format: "Please enter valid Email Address",
                        }}
                      ></GelEmailInput>
                    </GelFormField>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelFormField label="Mobile Phone Number">
                      <GelPhoneNumberInput
                        name="mobilePhoneNumber"
                        value={formData.mobilePhoneNumber}
                        onChange={onFormDataChange}
                        required
                        errorMsg={{
                          required: "Please enter Mobile Phone Number",
                        }}
                      />
                    </GelFormField>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelBoxLayout
                      gap="small"
                      space="auto"
                      style={{ justifyContent: "left" }}
                    >
                      <GelFormField>
                        <GelButton
                          name=""
                          secondary
                          large
                          onClick={backtoLogin}
                        >
                          Back
                        </GelButton>
                      </GelFormField>
                      <GelFormField>
                        <GelButton name="" primary large submit>
                          Submit
                        </GelButton>
                      </GelFormField>
                    </GelBoxLayout>
                  </GelCol>
                </GelRow>
              </GelColumnLayout>
              <br />
            </GelForm>
            {showSubmitModal && (
              <GelModal
                width={getGelTokens().global.sizeBaseUnit * 200}
                open={showSubmitModal}
                onClose={onModalClose}
                closeOnBackdropClick={false}
                action={
                  <GelBoxLayout gap="small" space="auto">
                    <GelButton
                      primary
                      medium
                      width={getGelTokens().global.sizeBaseUnit * 22}
                      onClick={backtoLogin}
                    >
                      Close
                    </GelButton>
                  </GelBoxLayout>
                }
              >
                <OrgSubmitStatus />
              </GelModal>
            )}
          </StyledOrganisationContainer>
        </GelPageContent>
      </GelPageLayout>
    </>
  );
};

const StyledOrganisationContainer = styled.div`
  ${() =>
    `
    float: left;
    margin-left:  202px;
    margin-top:  40px;
  `}
`;

export const StyledHeaderText = styled.span(() => ({
  color: getGelTokens().global.themeColorInteractive1Active,
  fontSize: `${getGelTokens().global.fontSizeHeadingMedium}px`,
  fontFamily: getGelTokens().global.fontFamilySans,
  textDecoration: "underline",
  fontWeight: getGelTokens().global.fontWeightBold,
}));

export const StyledSubHeaderNumberText = styled.span(() => ({
  color: getGelTokens().global.themeColorInteractive1Active,
  fontSize: `${getGelTokens().global.fontSizeHeadingMedium}px`,
  fontFamily: getGelTokens().global.fontFamilySans,
  fontWeight: getGelTokens().global.fontWeightBold,
  paddingTop: getGelTokens().global.sizeBaseX6,
}));

export const StyledSubHeaderText = styled.span(() => ({
  color: getGelTokens().global.themeColorInteractive1Active,
  fontSize: `${getGelTokens().global.fontSizeHeadingMedium}px`,
  fontFamily: getGelTokens().global.fontFamilySans,
  fontWeight: getGelTokens().global.fontWeightBold,
  paddingTop: getGelTokens().global.sizeBaseX6,
  paddingLeft: getGelTokens().global.sizeBaseX10,
}));

export const StyledParagraphNumber = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
}));

export const StyledParaStart = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeCaption,
}));

export const StyledParaLink = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextAccent,
  fontSize: getGelTokens().global.fontSizeCaption,
  textDecoration: "underline",
  display: "block",
}));

export const StyledLink = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextAccent,
  fontSize: getGelTokens().global.fontSizeCaption,
  textDecoration: "underline",
}));

export const StyledParagraphBulletFirst = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX16,
  display: "block",
}));

export const StyledParaText = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  display: "block",
}));

export const StyledParaTextParticipant = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX2}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  display: "block",
}));

export const StyledParaSubText = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  display: "block",
}));

export const StyledParagraphBulletSub = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX32,
}));

export const StyledParagraph = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX12,
}));

export const StyledSubParagraph = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX11,
}));

export const StyledBulletParagraph = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  display: "inline-block",
}));

export const StyledParagraphSub = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX17,
  display: "block",
}));

export default Organisation;
