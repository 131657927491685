import {
  GelParagraph,
  GelContainerLite,
  GelColumnLayout,
  GelRow,
  GelCol,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { Fund } from "../models/Fund";

const appendFundName = (
  benefitType: string,
  fundName: string,
  benefitValue: string
) => {
  if (fundName === "NSW Police Blue Ribbon") {
    if (benefitValue === "tpdaCheckBox" || benefitValue === "tiaCheckBox")
      return `${benefitType} - Aware`;
    else return `${benefitType} - PBRI`;
  }

  return benefitType;
};

interface ClaimSummaryProps {
  lodgement: any;
  funds: Fund[] | null;
}

const ClaimSummary = (props: ClaimSummaryProps) => {
  const { lodgement, funds } = props;
  const fundName = getFundName();

  const labelMapper: any = {
    fund: "Fund",
    memberNumber:
      fundName === "NSW Police Blue Ribbon"
        ? "Police Blue Ribbon Insurance Account Number"
        : "Member Number",
    title: "Title",
    firstName: "First name",
    lastName: "Last name",
    dateOfBirth: "Date of birth",
    claimReason: "Main cause of claim",
    lastDayOfWork: "Last day of work",
    benefitTypes: "Benefit type(s)",
    employed: "Employment-related Illness/Injury",
    mobile: "Mobile number",
    home: "Home number",
    emailAddress: "Email address",
    residentialAddress: "Residential address",
    thirdParty: "Third-party representation",
    ippCheckBox: "Income Protection (IP)",
    tpdpCheckBox: "Total Permanent Disability (TPD)",
    tipCheckBox: "Terminal Illness (TI)",
    tpdaCheckBox: "Total Permanent Disability (TPD)",
    tiaCheckBox: "Terminal Illness (TI)",
  };

  interface Row {
    id: any;
    data: any;
  }

  const formatDate = (date: any) => {
    if (date) {
      date = date.split("T")[0];
      const dateValues = date.split("-");
      return dateValues[2] + "/" + dateValues[1] + "/" + dateValues[0];
    }
  };

  const newLineText = (text: string) => {
    return text.split("\n").map((str) => <GelParagraph>{str}</GelParagraph>);
  };

  const tableInput: Row[] = [];

  // eslint-disable-next-line
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(lodgement || {})) {
    if (
      ![
        "memberDetails",
        "benefitDetails",
        "contactInformation",
        "authorisation",
      ].includes(key)
    ) {
      continue;
    }
    console.log("value = " + value);
    console.log("key = " + key);
    let val: any = value;
    for (const [k, v] of Object.entries(val)) {
      if (
        k === "ippCheckBox" ||
        k === "tpdpCheckBox" ||
        k === "tipCheckBox" ||
        k === "tpdaCheckBox" ||
        k === "tiaCheckBox"
      ) {
        if (v) {
          const row = tableInput.find(
            (x) => x.id === labelMapper["benefitTypes"]
          );
          if (row) {
            row.data =
              row.data + "\n" + appendFundName(labelMapper[k], fundName, k);
          } else {
            const row: Row = {
              id: labelMapper["benefitTypes"],
              data: appendFundName(labelMapper[k], fundName, k),
            };
            tableInput.push(row);
          }
        }
      } else if (
        k === "address1" ||
        k === "address2" ||
        k === "suburb" ||
        k === "state" ||
        k === "postalCode"
      ) {
        if (v) {
          const row = tableInput.find(
            (x) => x.id === labelMapper["residentialAddress"]
          );
          if (row) {
            row.data = row.data + ", " + v;
          } else {
            const row: Row = {
              id: labelMapper["residentialAddress"],
              data: v,
            };
            tableInput.push(row);
          }
        }
      } else if (k === "fundId") {
        console.log("funds", funds);
        const row: Row = {
          id: labelMapper["fund"],
          data: getFundName(),
        };
        tableInput.push(row);
      } else {
        if (labelMapper.hasOwnProperty(k)) {
          if (
            k === "awareMemberNumber" &&
            getFundName() !== "NSW Police Blue Ribbon"
          ) {
            continue;
          }
          if (k === "employed" && getFundName() !== "NSW Police Blue Ribbon") {
            continue;
          }
          if (k === "dateOfBirth" || k === "lastDayOfWork") {
            const row: Row = {
              id: labelMapper[k],
              data: formatDate(v) || "-",
            };
            tableInput.push(row);
          } else {
            const row: Row = {
              id: labelMapper[k],
              data: v || "-",
            };
            tableInput.push(row);
          }
        }
      }
    }
  }
  return (
    <GelContainerLite
      style={{
        paddingLeft: getGelTokens().global.sizeBaseX20,
        paddingTop: getGelTokens().global.sizeBaseX5,
        width: "100%",
      }}
    >
      <GelColumnLayout
        style={{
          backgroundColor: getGelTokens().global.themeColorBackgroundDefault,
          width: "98%",
          border: "1px solid" + getGelTokens().global.themeColorBorderDefault,
        }}
      >
        <GelRow>
          <GelCol></GelCol>
          <GelCol></GelCol>
        </GelRow>
        {tableInput.map((row, index) => (
          <GelRow
            key={index}
            style={{
              borderBottom:
                row.id !== labelMapper["thirdParty"]
                  ? "1px solid" + getGelTokens().global.themeColorBorderDefault
                  : "",
              margin: getGelTokens().global.sizeNone,
              paddingBottom: getGelTokens().global.sizeBaseX4,
            }}
            data-testid={row.id}
          >
            <GelCol
              style={{
                maxWidth: "45%",
                textAlign: "left",
              }}
            >
              <GelParagraph
                as="small"
                style={{
                  color: getGelTokens().global.themeColorTextDefault,
                }}
              >
                {row.id}
              </GelParagraph>
            </GelCol>
            <GelCol
              style={{
                maxWidth: "55%",
                textAlign: "left",
              }}
            >
              <GelParagraph
                as="small"
                style={{
                  color: getGelTokens().global.themeColorTextLight,
                }}
              >
                {newLineText(row.data)}
              </GelParagraph>
            </GelCol>
          </GelRow>
        ))}
      </GelColumnLayout>
    </GelContainerLite>
  );

  function getFundName(): any {
    return funds
      ? funds.find((fund) => fund.fundId === lodgement?.memberDetails?.fundId)
          ?.fundName
      : "";
  }
};
export default ClaimSummary;
