import { GelParagraph, GelIcon } from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";

const FileTypes = () => {
  return (
    <>
      <GelParagraph>Here are the supported file types:</GelParagraph>
      <div style={{ display: "flex" }}>
        <GelIcon
          color={getGelTokens().global.themeColorIconDefault}
          name="Bullet"
          width={getGelTokens().global.sizeBaseX4}
          height={getGelTokens().global.sizeBaseX4}
          style={{ "padding-top": "2px" }}
        />
        <GelParagraph>Portable Document Format (pdf)</GelParagraph>
      </div>
      <div style={{ display: "flex" }}>
        <GelIcon
          color={getGelTokens().global.themeColorIconDefault}
          name="Bullet"
          width={getGelTokens().global.sizeBaseX4}
          height={getGelTokens().global.sizeBaseX4}
          style={{ "padding-top": "2px" }}
        />
        <GelParagraph>
          Image Files (tif, tiff, gif, mdi, jpg, jpeg, png)
        </GelParagraph>
      </div>
      <div style={{ display: "flex" }}>
        <GelIcon
          color={getGelTokens().global.themeColorIconDefault}
          name="Bullet"
          width={getGelTokens().global.sizeBaseX4}
          height={getGelTokens().global.sizeBaseX4}
          style={{ "padding-top": "2px" }}
        />
        <GelParagraph>Microsoft Word Files (doc, docx)</GelParagraph>
      </div>
      <div style={{ display: "flex" }}>
        <GelIcon
          color={getGelTokens().global.themeColorIconDefault}
          name="Bullet"
          width={getGelTokens().global.sizeBaseX4}
          height={getGelTokens().global.sizeBaseX4}
          style={{ "padding-top": "2px" }}
        />
        <GelParagraph>Microsoft Excel Files (xls, xlsx)</GelParagraph>
      </div>
      <div style={{ display: "flex" }}>
        <GelIcon
          color={getGelTokens().global.themeColorIconDefault}
          name="Bullet"
          width={getGelTokens().global.sizeBaseX4}
          height={getGelTokens().global.sizeBaseX4}
          style={{ "padding-top": "2px" }}
        />
        <GelParagraph>Microsoft Powerpoint Files (ppt, pptx, ppz)</GelParagraph>
      </div>
      <div style={{ display: "flex" }}>
        <GelIcon
          color={getGelTokens().global.themeColorIconDefault}
          name="Bullet"
          width={getGelTokens().global.sizeBaseX4}
          height={getGelTokens().global.sizeBaseX4}
          style={{ "padding-top": "2px" }}
        />
        <GelParagraph>Comma-Separated Values (csv)</GelParagraph>
      </div>
      <div style={{ display: "flex" }}>
        <GelIcon
          color={getGelTokens().global.themeColorIconDefault}
          name="Bullet"
          width={getGelTokens().global.sizeBaseX4}
          height={getGelTokens().global.sizeBaseX4}
          style={{ "padding-top": "2px" }}
        />
        <GelParagraph>Rich Text Format (rtf)</GelParagraph>
      </div>
      <div style={{ display: "flex" }}>
        <GelIcon
          color={getGelTokens().global.themeColorIconDefault}
          name="Bullet"
          width={getGelTokens().global.sizeBaseX4}
          height={getGelTokens().global.sizeBaseX4}
          style={{ "padding-top": "2px" }}
        />
        <GelParagraph>Email Message (msg)</GelParagraph>
      </div>
      <div style={{ display: "flex" }}>
        <GelIcon
          color={getGelTokens().global.themeColorIconDefault}
          name="Bullet"
          width={getGelTokens().global.sizeBaseX4}
          height={getGelTokens().global.sizeBaseX4}
          style={{ "padding-top": "2px" }}
        />
        <GelParagraph>HTM Files (htm, html)</GelParagraph>
      </div>
    </>
  );
};

export default FileTypes;
