import {
  GelPageLayout,
  GelPageContent,
  GelPageHeader,
  GelHeading3,
  GelTextInput,
  GelForm,
  GelFormField,
  useGelFormData,
  GelPostcodeInput,
  GelColumnLayout,
  GelRow,
  GelCol,
  GelSelect,
  GelButton,
  GelSpinner,
  GelBoxLayout,
} from "@tal-gel/components";
import { useNavigate, useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { getGelTokens } from "@tal-gel/theming";
import styled from "@emotion/styled";
import AccountHeader from "../../components/layout/AccountHeader";
import { useState, useEffect } from "react";
import axios from "axios";
import OrgSubmitStatus from "./OrgSubmitStatus";
import { AxiosError } from "../../models/AxiosError";
import { Organisation } from "../../models/Organisation";
import { Branch } from "../../models/Branch";
import { BranchToOrganisation } from "../../models/BranchToOrganisation";

const EditOrganisation = () => {
  const navigate = useNavigate();
  const { organisationId } = useParams();
  const [loading, setLoading] = useState(false);
  const [organisation, setOrganisation] = useState<Organisation | null>(null);
  const { formData, onFormDataChange, shouldValidateAfterInit, setFormData } =
    useGelFormData({
      organisationName: "",
      branchName: "",
      postCode: "",
      state: "",
    });

  const getOrganisationQuery = `query Organisation($organisationId: String!) {
    organisationByOrganisationId(organisationId: $organisationId) {
      organisationId,
      organisationName,
      branchDto{
        branchId,
        branchName
      }
    }
  }`;

  const loadOrganisation = async () => {
    setLoading(true);
    const variables = {
      organisationId: organisationId,
    };
    try {
      const existingOrganisationResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
        {
          query: getOrganisationQuery,
          variables,
        }
      );
      setOrganisation(
        existingOrganisationResponse.data.data.organisationByOrganisationId
      );
      setFormData({
        organisationName:
          existingOrganisationResponse.data.data.organisationByOrganisationId
            ?.organisationName,
      });
    } catch {
      navigate("/error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (organisationId) {
      loadOrganisation();
    }
  }, []);

  const backtoUserMgrDashboard = () => {
    navigate("/manageUsers");
  };

  const isNullOrUndefined = (value: any): boolean =>
    value === undefined || value === null;

  const addBranch = async () => {
    if (
      isNullOrUndefined(formData.branchName) ||
      isNullOrUndefined(formData.postCode) ||
      isNullOrUndefined(formData.state)
    ) {
      // Handle the case where any of the fields are null or undefined
      console.log("One or more fields are null or undefined.");
      return;
    } else {
      setLoading(true);
      const branch: BranchToOrganisation = {
        branchName: formData.branchName,
        postCode: formData.postCode,
        state: formData.state,
        organisationId: organisationId,
      };
      const addBranchQuery = `
      mutation AddBranchToOrganisation($saveBranchRequest: SaveBranchRequestInput!) {
        addBranchToOrganisation(saveBranchRequest: $saveBranchRequest) {
          branchId
      }
    }`;
      const variables = {
        saveBranchRequest: {
          branch,
        },
      };

      try {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/graphql/`, {
          query: addBranchQuery,
          variables: variables,
        });
      } catch (error: any) {
        if (
          error?.response?.data?.errors &&
          Array.isArray(error.response.data.errors) &&
          error.response.data.errors.length > 0
        ) {
          var errorMsg = error.response.data.errors[0].message;
          if (errorMsg.indexOf("Branch Already Exists") !== -1)
            navigate("/duplicateBranch");
          // if (errorMsg.indexOf("is not allowed with this email domain") !== -1)
          //   navigate("/invalidDomainForUserError");
          else navigate("/addBranchError");
        } else navigate("/addBranchError");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <GelPageLayout>
        <GelPageHeader>
          <AccountHeader />
        </GelPageHeader>
        <GelPageContent>
          <StyledOrganisationContainer>
            <GelForm
              labelAtTop
              disableOnSubmit
              //onSubmit={addBranch}
              shouldValidateAfterInit={shouldValidateAfterInit}
            >
              {loading && <GelSpinner large overlay />}
              <GelHeading3>Edit Organisation</GelHeading3>
              <br />
              <GelColumnLayout
                style={{
                  backgroundColor:
                    getGelTokens().global.themeColorBackgroundDefault,
                  width: "60%",
                  align: "center",
                }}
              >
                <GelRow>
                  <GelCol>
                    <GelFormField label="Organisation Name">
                      <GelTextInput
                        name="organisationName"
                        data-testid="organisationName"
                        maxLength={120}
                        onChange={onFormDataChange}
                        value={formData.organisationName}
                        required
                        disabled
                        errorMsg={{
                          required: "Please enter Organisation Name",
                        }}
                      ></GelTextInput>
                    </GelFormField>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelHeading3>Add New Branch</GelHeading3>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelFormField label="Branch Name">
                      <GelTextInput
                        name="branchName"
                        data-testid="branchName"
                        maxLength={120}
                        onChange={onFormDataChange}
                        value={formData.branchName}
                        required
                        errorMsg={{
                          required: "Please enter Branch Name",
                        }}
                      ></GelTextInput>
                    </GelFormField>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelColumnLayout distribution="1/2 1/2">
                      <GelFormField label="Postcode">
                        <GelPostcodeInput
                          name="postCode"
                          value={formData.postCode}
                          onChange={onFormDataChange}
                          required
                          errorMsg={{
                            required: "Please enter a Postcode",
                          }}
                        />
                      </GelFormField>
                      <GelFormField label="State">
                        <GelSelect
                          name="state"
                          value={formData.state}
                          onChange={onFormDataChange}
                          placeholder="Please select"
                          options={[
                            { label: "ACT", value: "ACT" },
                            { label: "NSW", value: "NSW" },
                            { label: "NT", value: "NT" },
                            { label: "QLD", value: "QLD" },
                            { label: "SA", value: "SA" },
                            { label: "TAS", value: "TAS" },
                            { label: "VIC", value: "VIC" },
                            { label: "WA", value: "WA" },
                          ]}
                          required
                          errorMsg={{
                            required: "Please select a State",
                          }}
                        />
                      </GelFormField>
                    </GelColumnLayout>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelBoxLayout
                      gap="small"
                      space="auto"
                      style={{ justifyContent: "left" }}
                    >
                      <GelFormField>
                        <GelButton
                          name=""
                          secondary
                          large
                          onClick={backtoUserMgrDashboard}
                        >
                          Back
                        </GelButton>
                      </GelFormField>
                      <GelFormField>
                        <GelButton
                          name=""
                          primary
                          large
                          submit
                          onClick={addBranch}
                        >
                          Confirm
                        </GelButton>
                      </GelFormField>
                    </GelBoxLayout>
                  </GelCol>
                </GelRow>
              </GelColumnLayout>

              <br />
            </GelForm>
            {/* {showSubmitModal && (
              <GelModal
                width={getGelTokens().global.sizeBaseUnit * 200}
                open={showSubmitModal}
                onClose={onModalClose}
                closeOnBackdropClick={false}
                action={
                  <GelBoxLayout gap="small" space="auto">
                    <GelButton
                      primary
                      medium
                      width={getGelTokens().global.sizeBaseUnit * 22}
                      onClick={backtoLogin}
                    >
                      Close
                    </GelButton>
                  </GelBoxLayout>
                }
              >
                <OrgSubmitStatus />
              </GelModal>
            )} */}
          </StyledOrganisationContainer>
        </GelPageContent>
      </GelPageLayout>
    </>
  );
};

const StyledOrganisationContainer = styled.div`
  ${() =>
    `
    float: left;
    margin-left:  202px;
    margin-top:  40px;
  `}
`;

export const StyledHeaderText = styled.span(() => ({
  color: getGelTokens().global.themeColorInteractive1Active,
  fontSize: `${getGelTokens().global.fontSizeHeadingMedium}px`,
  fontFamily: getGelTokens().global.fontFamilySans,
  textDecoration: "underline",
  fontWeight: getGelTokens().global.fontWeightBold,
}));

export const StyledSubHeaderNumberText = styled.span(() => ({
  color: getGelTokens().global.themeColorInteractive1Active,
  fontSize: `${getGelTokens().global.fontSizeHeadingMedium}px`,
  fontFamily: getGelTokens().global.fontFamilySans,
  fontWeight: getGelTokens().global.fontWeightBold,
  paddingTop: getGelTokens().global.sizeBaseX6,
}));

export const StyledSubHeaderText = styled.span(() => ({
  color: getGelTokens().global.themeColorInteractive1Active,
  fontSize: `${getGelTokens().global.fontSizeHeadingMedium}px`,
  fontFamily: getGelTokens().global.fontFamilySans,
  fontWeight: getGelTokens().global.fontWeightBold,
  paddingTop: getGelTokens().global.sizeBaseX6,
  paddingLeft: getGelTokens().global.sizeBaseX10,
}));

export const StyledParagraphNumber = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
}));

export const StyledParaStart = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeCaption,
}));

export const StyledParaLink = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextAccent,
  fontSize: getGelTokens().global.fontSizeCaption,
  textDecoration: "underline",
  display: "block",
}));

export const StyledLink = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextAccent,
  fontSize: getGelTokens().global.fontSizeCaption,
  textDecoration: "underline",
}));

export const StyledParagraphBulletFirst = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX16,
  display: "block",
}));

export const StyledParaText = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  display: "block",
}));

export const StyledParaTextParticipant = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX2}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  display: "block",
}));

export const StyledParaSubText = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  display: "block",
}));

export const StyledParagraphBulletSub = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX32,
}));

export const StyledParagraph = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX12,
}));

export const StyledSubParagraph = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX11,
}));

export const StyledBulletParagraph = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  display: "inline-block",
}));

export const StyledParagraphSub = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX17,
  display: "block",
}));

export default EditOrganisation;
