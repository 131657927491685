import styled from "@emotion/styled";
import {
  GelButton,
  GelIcon,
  GelParagraph,
  GelRow,
  GelCol,
  GelSpinner,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";

const maxFileSize = 157286400;

const supportedFileTypes = [
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "ppz",
  "csv",
  "txt",
  "pdf",
  "rdf",
  "tif",
  "tiff",
  "gif",
  "mdi",
  "jpg",
  "jpeg",
  "png",
  "htm",
  "html",
  "msg",
];

const statuses = new Map<number, string>([
  [1, "Uploading"],
  [2, "Success"],
  [4, "Vulnerable"],
]);
const logEventQuery = `
    mutation LogLodgementEvent($lodgementId: String!, $lodgementEvent: String!, $metadata: String!) {
  logLodgementEvent(lodgementId: $lodgementId, lodgementEvent: $lodgementEvent, metadata: $metadata)
}`;

const deleteFileQuery = `
    mutation DeleteFile($lodgementId: String!,$fileScanId: Int,  $fileName: String!, $fileCategory: String!, ) {
  deleteFile(lodgementId: $lodgementId,fileScanId: $fileScanId,  fileName: $fileName, fileCategory:$fileCategory)
}`;
interface UploaderProps {
  lodgement: any;
  setLodgement: (lodgement: any) => any;
  type: string;
  hasNoUploadedFilesError?: boolean;
  setHasNoUploadedFilesError?: any;
  setIsUploading?: (isUploading: boolean) => void;
}

const Uploader = (props: UploaderProps) => {
  const {
    lodgement,
    setLodgement,
    type,
    hasNoUploadedFilesError,
    setHasNoUploadedFilesError,
    setIsUploading,
  } = props;

  const [isUploadingLocal, setIsUploadingLocal] = useState(false);
  var [fileList, setFileList] = useState<any>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [fileIdStatus, setFileIdStatus] = useState<Map<string, string>>(
    new Map<string, string>([])
  );
  let fileIdStatusMap = new Map<string, string>([]);

  const [fileNameId, setFileNameId] = useState<Map<string, string>>(
    new Map<string, string>([])
  );
  let fileNameIdMap = new Map<string, string>([]);

  useEffect(() => {
    if (lodgement?.files) {
      const relevantFiles: any[] = lodgement.files.filter(
        (file: any) => file.category === type
      );

      if (relevantFiles.length > 0) {
        setFileList([...fileList, ...relevantFiles.map(toFile)]);
        relevantFiles.forEach((file: any) => {
          setFileStatus(
            fileNameIdMap,
            toFile(file),
            file.fileId,
            fileIdStatusMap,
            mapStatus(file.fileStatus)
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lodgement, setLodgement]);

  const toFile = (file: any) => {
    return {
      id: file.fileId,
      status: mapStatus(file.fileStatus),
      name: file.fileName,
    };
  };

  const mapStatus = (id: number): string => {
    return statuses.get(id) || "";
  };

  const handleUploadClick = async () => {
    inputRef.current?.click();
    if (!fileList) {
      return;
    }
  };

  const handleDeleteFileClick = async (file: any) => {
    if (file) {
      if (lodgement?.files) {
        const fileToUpdate = lodgement.files.find(
          (lodgementFile: any) => lodgementFile.fileName === file.name
        );

        if (fileToUpdate) {
          // Update the status of the found file
          file.id = fileToUpdate.fileId;
        }
        if (file.id) {
          lodgement.files = lodgement.files.filter(
            (scannedFile: any) => scannedFile.fileId !== file.id
          );
        }
      }
      if (file.id) {
        fileList = fileList.filter(
          (uploadedFile: any) => uploadedFile.id !== file.id
        );
        if (fileIdStatus.has(file.id)) fileIdStatus.delete(file.id);
      } else {
        fileList = fileList.filter(
          (uploadedFile: any) => uploadedFile.name !== file.name
        );
        if (fileIdStatus.has(file.name)) fileIdStatus.delete(file.name);
      }
      if (fileNameId.has(file.name)) fileNameId.delete(file.name);
      setFileList(fileList);
      setFileIdStatus(fileIdStatus);
      setFileNameId(fileNameId);
    }
    const deleteEvent = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/graphql/`, {
          query: deleteFileQuery,
          variables: {
            lodgementId: lodgement?.lodgementId,
            fileScanId: file.id,
            fileName: file.name,
            fileCategory: type,
            // lodgementEvent: "DELETEFILE",
            // lodgementId: lodgement?.lodgementId,
            // metadata:
            //   "Category - " +
            //   type +
            //   ",name - " +
            //   file.name +
            //   ",Id - " +
            //   file.id,
          },
        });
      } catch {}
    };
    deleteEvent();
  };

  const files = fileList ? [...fileList] : [];

  const uploadFiles = async (files: any) => {
    setIsUploadingLocal(true);
    let successfulUploads = 0;
    let invalidUploads = 0;

    const handleFileUploadComplete = () => {
      setIsUploadingLocal(false);
      if (setIsUploading) {
        setIsUploading(false);
      }
    };

    let fileIdStatusMap = fileIdStatus;
    let fileNameIdMap = fileNameId;

    const uploadFile = async (file: any) => {
      const errorStatus = checkFile(file);
      if (errorStatus) {
        setFileStatus(
          fileNameIdMap,
          file,
          file.name,
          fileIdStatusMap,
          errorStatus
        );
        invalidUploads++;
        setIsUploadingLocal(false);
        const logEvent = async () => {
          try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/graphql/`, {
              query: logEventQuery,
              variables: {
                lodgementEvent: "INVALIDFILEUPLOAD",
                lodgementId: lodgement?.lodgementId,
                metadata: "Category - " + type + ", error - " + errorStatus,
              },
            });
          } catch {}
        };
        logEvent();
        return null;
      }

      try {
        const data = new FormData();
        data.append(`0`, file);
        data.append(
          "operations",
          JSON.stringify({
            query:
              "mutation ($file: Upload!, $lodgementId: String!, $category: String!) { uploadFile(file: $file, lodgementId: $lodgementId, category: $category){id,scanStatus, scanStatusName,statusReason} }",
            variables: {
              file: null,
              lodgementId: lodgement?.lodgementId,
              category: type,
            },
          })
        );
        data.append("map", JSON.stringify({ "0": ["variables.file"] }));

        if (setIsUploading) {
          setIsUploading(true);
        }
        const uploadResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/graphql`,
          data,
          {
            headers: {
              "GraphQL-preflight": 1,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const responseData = uploadResponse.data.data.uploadFile;

        setFileStatus(
          fileNameIdMap,
          file,
          responseData[0].id,
          fileIdStatusMap,
          responseData[0].scanStatusName
        );

        return { id: responseData[0].id, name: file.name };
      } catch {
        setFileStatus(
          fileNameIdMap,
          file,
          file.name,
          fileIdStatusMap,
          "Upload failed, please try again"
        );
        return null;
      }
    };

    const getFileStatus = async (fileId: string, fileName: string) => {
      const fileIdsCSV = [fileId].join(",");
      const statusResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
        {
          query: `query {
          scanStatus(fileIds: "${fileIdsCSV}", lodgementId: "${lodgement?.lodgementId}") {
            id,
            scanStatus,
            scanStatusName,
            statusReason
          }
        }`,
        }
      );

      if (statusResponse.data.data.scanStatus.length === 0) {
        await new Promise((f) => setTimeout(f, 3000));
        await getFileStatus(fileId, fileName);
      } else {
        const status = statusResponse.data.data.scanStatus[0];
        setFileStatus(
          fileNameIdMap,
          { name: fileName },
          status.id,
          fileIdStatusMap,
          status.scanStatusName
        );

        const newFiles = [
          {
            fileId: status.id,
            fileName: fileName,
            fileStatus: status.scanStatus,
            category: type,
            statusReason: status.statusReason,
          },
        ];

        lodgement.files = lodgement.files
          ? [...lodgement.files, ...newFiles]
          : newFiles;
        setLodgement(lodgement);

        const uploadedFiles = lodgement.files.filter(
          (file: any) => file.category !== "authorisation"
        );

        if (hasNoUploadedFilesError) {
          if (uploadedFiles.length > 0) {
            const validFiles = uploadedFiles.filter(
              (file: any) => file.fileStatus === "2"
            );
            if (validFiles.length > 0) {
              setHasNoUploadedFilesError(false);
            }
          }
        }
        successfulUploads++;
        if (successfulUploads === files.length - invalidUploads) {
          handleFileUploadComplete();
        }
      }
    };

    if (!Array.isArray(files)) {
      files = Array.from(files);
    }
    const fileIds = await Promise.all(files.map(uploadFile));
    const validFileIds = fileIds.filter((file) => file !== null);

    for (const { id, name } of validFileIds) {
      await getFileStatus(id, name);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFileList([...fileList, ...e.target.files]);
    uploadFiles(e.target.files);
  };

  const handleDragOver = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    const dataTransferItems = event.dataTransfer.items;
    const droppedFilesArray = [];
    for (let i = 0; i < dataTransferItems.length; i++) {
      const item = dataTransferItems[i];
      if (item.kind === "file") {
        droppedFilesArray.push(item.getAsFile());
      }
    }
    setFileList([...fileList, ...droppedFilesArray]);
    uploadFiles(droppedFilesArray);
  };

  const hasSupportedExtension = (name: string) => {
    return supportedFileTypes.some((extension) => {
      var lastIndex = name.lastIndexOf(extension);
      return lastIndex !== -1 && lastIndex + extension.length === name.length;
    });
  };

  const checkFileType = (file: any) => {
    if (!hasSupportedExtension(file.name)) {
      return "File format not supported";
    }
    return null;
  };

  const checkFileSize = (file: any) => {
    if (file.size > maxFileSize) {
      return "File size too large";
    }
    return null;
  };

  const checkFile = (file: any) => {
    const errorMessage = checkFileType(file) || checkFileSize(file);
    return errorMessage;
  };

  const setFileStatus = (
    fileNameIdMap: Map<string, string>,
    file: any,
    id: string,
    fileIdStatusMap: Map<string, string>,
    status: string
  ) => {
    fileNameIdMap.set(file.name, id);
    setFileNameId(new Map<string, string>([]));
    setFileNameId(fileNameIdMap);

    fileIdStatusMap.set(id, status);
    setFileIdStatus(new Map<string, string>([]));
    setFileIdStatus(fileIdStatusMap);
  };

  const getIcon = (status: string | undefined) => {
    if (status === "Success" || status === "Sanitised") {
      return (
        <GelIcon
          name="Attachment"
          width={getGelTokens().global.sizeBaseX6}
          height={getGelTokens().global.sizeBaseX6}
        />
      );
    } else if (
      status === "Vulnerable" ||
      status === "Failed" ||
      status === "File size too large" ||
      status === "File format not supported" ||
      status === "Upload failed, please try again"
    ) {
      return (
        <GelIcon
          name="AlertCircle"
          width={getGelTokens().global.sizeBaseX6}
          height={getGelTokens().global.sizeBaseX6}
          color={getGelTokens().global.themeColorIconDanger}
        />
      );
    } else {
      return <GelSpinner small overlay={false} />;
    }
  };

  const getMessage = (status: string | undefined) => {
    if (status === "" || status === undefined) {
      return "Uploading";
    } else if (status === "Success" || status === "Sanitised") {
      return "Success";
    } else if (status === "Vulnerable" || status === "Failed") {
      return "Upload failed, please try again";
    }
    return status;
  };
  const showBinIcon = (status: string | undefined) => {
    if (
      status === "" ||
      status === undefined ||
      status === "Success" ||
      status === "Sanitised" ||
      status === "Scanning"
    ) {
      return false;
    } else return true;
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{ paddingBottom: getGelTokens().global.sizeBaseX4 }}
    >
      <StyledAlertDiv
        style={{
          backgroundColor: hasNoUploadedFilesError
            ? "#F3D9E2"
            : getGelTokens().global.themeColorBackgroundSuccessLight,
          border: hasNoUploadedFilesError
            ? `4px solid ${getGelTokens().global.themeColorIconDanger}`
            : "",
          borderRadius: hasNoUploadedFilesError ? "10px" : "",
        }}
      >
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "32px",
          }}
        >
          <GelIcon
            name="Upload"
            width={getGelTokens().global.sizeBaseX8}
            height={getGelTokens().global.sizeBaseX8}
            color={
              hasNoUploadedFilesError
                ? getGelTokens().global.themeColorIconDanger
                : getGelTokens().brand.brandColorPrimary1
            }
          />
        </div>
        <div>
          <GelButton
            tertiary
            medium
            onClick={handleUploadClick}
            disabled={isUploadingLocal}
          >
            Click to select files
          </GelButton>
        </div>
        <GelParagraph style={{ paddingTop: "10px", paddingLeft: "8px" }}>
          {" "}
          or drag and drop multiple files
        </GelParagraph>
      </StyledAlertDiv>
      {hasNoUploadedFilesError && (
        <GelParagraph
          style={{ color: "#e20f1a", paddingTop: "10px", paddingLeft: "8px" }}
        >
          Please upload a file
        </GelParagraph>
      )}
      {files.map((file, index) => (
        <GelRow
          style={{
            paddingLeft: getGelTokens().global.sizeBaseX16,
            paddingBottom: getGelTokens().global.sizeBaseX2,
            paddingTop: getGelTokens().global.sizeBaseX2,
          }}
          key={index}
        >
          <GelCol
            style={{
              maxWidth: "3%",
              textAlign: "left",
            }}
          >
            <GelRow style={{ paddingTop: getGelTokens().global.sizeBaseX2 }}>
              {getIcon(fileIdStatus?.get(fileNameId?.get(file.name) || ""))}
            </GelRow>
          </GelCol>
          <GelCol>
            <GelRow>
              <GelCol
                style={{
                  flex: 9,
                  paddingLeft: 0,
                }}
              >
                <GelParagraph>{file.name}</GelParagraph>
              </GelCol>
              <GelCol
                style={{
                  flex: 1,
                }}
              >
                {showBinIcon(
                  fileIdStatus?.get(fileNameId?.get(file.name) || "")
                ) && (
                  <GelIcon
                    name="Bin"
                    id={`${file.name}.binicon`}
                    width={getGelTokens().global.sizeBaseUnit * 4}
                    color={getGelTokens().brand.brandColorPrimary1}
                    onClick={() => handleDeleteFileClick(file)}
                  />
                )}
              </GelCol>
            </GelRow>

            <GelRow>
              <GelParagraph>
                {getMessage(
                  fileIdStatus?.get(fileNameId?.get(file.name) || "")
                )}
              </GelParagraph>
            </GelRow>
          </GelCol>
        </GelRow>
      ))}
      <input
        type="file"
        accept=".doc, .docx, .xls, .xlsx, .ppt, .ppz, .csv, .txt, .pdf, .rdf, .tif, .tiff, .gif, .mdi, .jpg, .jpeg, .png, .htm, .html, .msg"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        aria-label={type}
        multiple
      />
    </div>
  );
};

const StyledAlertDiv = styled.div`
  ${() =>
    `
    
    padding-top: 16px;
    padding-bottom: 36px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
  `}
`;

Uploader.defaultProps = {
  hasNoUploadedFilesError: false,
};

export default Uploader;
