import {
  GelContainerLite,
  GelFormStep,
  GelFormStepper,
  GelMultiStepForm,
  GelBoxLayout,
  GelButton,
} from "@tal-gel/components";
import styled from "@emotion/styled";
import { MemberDetailsStep } from "./MemberDetailsStep";
import { BenefitDetailsStep } from "./BenefitDetailsStep";
import { ContactInformationStep } from "./ContactInformationStep";
import { AuthorisationStep } from "./AuthorisationStep";
import { ReviewClaim } from "./ReviewClaim";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Fund } from "../../models/Fund";

interface FormProps {
  lodgement: any;
  setLodgement: (lodgement: any) => any;
  setShowForm: (showForm: boolean) => void;
  setShowReview: (showForm: boolean) => void;
  saveLodgement: (lodgement: any) => any;
  funds: Fund[] | null;
  selectedFund: string;
  setSelectedFund: (fundId: string) => void;
  setScreen: (screen: string) => void;
  setLoading: (loading: boolean) => void;
}

const Form = (props: FormProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const backToDashBoard = () => {
    navigate("/");
  };

  const setIsUploadingWrapper = (isUploading: boolean) => {
    setIsUploading(isUploading);
  };

  const {
    lodgement,
    setLodgement,
    setShowForm,
    selectedFund,
    setShowReview,
    saveLodgement,
    funds,
    setSelectedFund,
    setScreen,
    setLoading,
  } = props;
  return (
    <GelContainerLite fixed gutter={{ vertical: "large" }}>
      <GelMultiStepForm
        render={() => (
          <>
            <GelFormStepper>
              <GelFormStep title="Member details" data-testid="member-details">
                <MemberDetailsStep
                  lodgement={lodgement}
                  saveLodgement={saveLodgement}
                  funds={funds}
                  setSelectedFund={setSelectedFund}
                  setScreen={setScreen}
                />
              </GelFormStep>
              <GelFormStep
                title="Benefit details"
                data-testid="benefit-details"
              >
                <BenefitDetailsStep
                  lodgement={lodgement}
                  saveLodgement={saveLodgement}
                  funds={funds}
                  setScreen={setScreen}
                  setLoading={setLoading}
                />
              </GelFormStep>
              <GelFormStep
                title="Member's contact information"
                data-testid="contact-information"
              >
                <ContactInformationStep
                  lodgement={lodgement}
                  saveLodgement={saveLodgement}
                  setScreen={setScreen}
                />
              </GelFormStep>
              <GelFormStep
                title="Third-party authorisation"
                data-testid="authorisation"
              >
                <AuthorisationStep
                  lodgement={lodgement}
                  setLodgement={setLodgement}
                  saveLodgement={saveLodgement}
                  setIsUploadingForm={setIsUploadingWrapper}
                  funds={funds}
                  setScreen={setScreen}
                />
              </GelFormStep>
              <GelFormStep>
                <ReviewClaim
                  setShowForm={setShowForm}
                  setShowReview={setShowReview}
                />
              </GelFormStep>
            </GelFormStepper>
          </>
        )}
      />
      <GelBoxLayout space="auto" distribution="start">
        <GelButton
          secondary
          medium
          onClick={backToDashBoard}
          disabled={isUploading}
        >
          BACK TO DASHBOARD
        </GelButton>
      </GelBoxLayout>
    </GelContainerLite>
  );
};

export const StyledFormContainer = styled.div`
  ${() =>
    `
    text-align: left;
    width: 50%
  `}
`;

export default Form;
