const ISSUER = process.env.REACT_APP_OKTA_AUTH_SERVER;
if (ISSUER === undefined) {
  throw new Error("ISSUER is undefined");
}
const OKTA_DOMAIN = new URL(ISSUER).host;
const CUSTOM_AUTH_SERVER_ID = new URL(ISSUER).pathname
  .replace("/oauth2/", "")
  .replace("/", "");
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = process.env.PUBLIC_URL || "";
const REDIRECT_URI = `${window.location.origin}/`;
const OKTA_EMAIL_SUFFIX = "claimassisttal.com.au";

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: REDIRECT_URI,
    scopes: ["offline_access", "openid", "profile", "email"],
    pkce: true,
    tokenManager: {
      storage: "sessionStorage",
    },
  },
  oktaEmail: {
    suffix: OKTA_EMAIL_SUFFIX,
  },
  oktaDomain: {
    domain: OKTA_DOMAIN,
  },
};
