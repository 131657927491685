import {
  GelPageLayout,
  GelPageContent,
  GelParagraph,
  GelColumnLayout,
  GelRow,
  GelCol,
  GelContainerLite,
  GelBoxLayout,
  GelButton,
  GelSpinner,
} from "@tal-gel/components";
import styled from "@emotion/styled";
import { useState } from "react";
import { getGelTokens } from "@tal-gel/theming";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ClaimSummary from "../ClaimSummary";

const Review = (props: any) => {
  const { lodgement, setShowForm, setShowReview, setShowStatus, funds } = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onClickEdit = () => {
    setShowForm(true);
    setShowReview(false);
  };

  const saveAndExit = () => {
    navigate("/");
  };

  const onSubmit = async () => {
    setLoading(true);

    const query = `mutation SubmitClaim($submitClaimRequest: SubmitClaimRequestInput!) {
  submitClaim(submitClaimRequest: $submitClaimRequest) {
    ghqClaimId
  }
}`;
    const variables = {
      submitClaimRequest: {
            lodgementId: lodgement.lodgementId,
            memberSummarySsoTokenId: lodgement.memberDetails.memberSummarySsoTokenId,
            memberDetailsSsoTokenId: lodgement.memberDetails.memberDetailsSsoTokenId
      },
    };
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/graphql`, {
        query,
        variables,
      });
      setShowReview(false);
      setShowStatus("success");
    } catch (ex: any) {
      const errors = ex.response.data.errors;
      if (errors && errors[0] && errors[0].message === "Claim already exists") {
        setShowStatus("existing-claim");
      } else {
        setShowStatus("failure");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <GelPageLayout>
        <GelPageContent>
          <div
            style={{
              paddingLeft: getGelTokens().global.sizeBaseX16,
              paddingTop: getGelTokens().global.sizeBaseX4,
              width: "60.5%",
            }}
          >
            <StyledAlertDiv>
              <div
                style={{
                  paddingRight: "15px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22.5"
                  height="22.5"
                  viewBox="0 0 32 32"
                >
                  <path
                    fill="#5aafb3"
                    fillRule="evenodd"
                    d="M16,1 C24.271,1 31,7.729 31,16 C31,24.271 24.271,31 16,31 C7.729,31 1,24.271 1,16 C1,7.729 7.729,1 16,1 Z M16,3 C8.832,3 3,8.832 3,16 C3,23.168 8.832,29 16,29 C23.168,29 29,23.168 29,16 C29,8.832 23.168,3 16,3 Z M15.0668,13.1331 C16.1328,13.1331 16.9998,14.0001 16.9998,15.0661 L16.9998,15.0661 L16.9998,19.7331 C16.9998,20.2111 17.3888,20.6001 17.8658,20.6001 L17.8658,20.6001 L18.7998,20.6001 C19.3528,20.6001 19.7998,21.0481 19.7998,21.6001 C19.7998,22.1521 19.3528,22.6001 18.7998,22.6001 L18.7998,22.6001 L17.8658,22.6001 C16.2858,22.6001 14.9998,21.3141 14.9998,19.7331 L14.9998,19.7331 L14.9998,15.0661 L14.1328,15.1331 C13.5798,15.1331 13.1328,14.6851 13.1328,14.1331 C13.1328,13.5811 13.5798,13.1331 14.1328,13.1331 L14.1328,13.1331 Z M15.5332,8.4669 C16.3422,8.4669 17.0002,9.1249 17.0002,9.9329 C17.0002,10.7419 16.3422,11.3999 15.5332,11.3999 C14.7242,11.3999 14.0662,10.7419 14.0662,9.9329 C14.0662,9.1249 14.7242,8.4669 15.5332,8.4669 Z"
                  ></path>
                </svg>
              </div>
              <div>
                <strong>
                  Please ensure that the claim details are correct
                </strong>
                <GelParagraph
                  as="small"
                  style={{
                    display: "block",
                    color: getGelTokens().global.themeColorTextLight,
                  }}
                >
                  You will not be able to edit the claim details after you
                  submit.
                </GelParagraph>
              </div>
            </StyledAlertDiv>
          </div>
          {loading && <GelSpinner large overlay />}
          <ClaimSummary lodgement={lodgement} funds={funds} />
          <GelBoxLayout
            space="auto"
            distribution="start"
            style={{
              paddingTop: getGelTokens().global.sizeBaseX4,
              paddingLeft: getGelTokens().global.sizeBaseX16,
            }}
          >
            <GelButton secondary medium onClick={saveAndExit}>
              SAVE & EXIT
            </GelButton>
            <GelButton secondary medium onClick={onClickEdit}>
              Edit details
            </GelButton>
            <GelButton primary medium onClick={onSubmit}>
              SUBMIT DETAILS
            </GelButton>
          </GelBoxLayout>
        </GelPageContent>
      </GelPageLayout>
    </>
  );
};

const StyledAlertDiv = styled.div`
  ${() =>
    `
    background-color: #d5f0f0;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 2px;
    margin: 16px 0;
    display: flex;
    text-align: left;
  `}
`;

export default Review;
