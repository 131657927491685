import { OktaAuthOptions } from "@okta/okta-auth-js";
export const oktaConfig: OktaAuthOptions = {
  issuer: process.env.REACT_APP_OKTA_AUTH_SERVER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  postLogoutRedirectUri: `${window.location.origin}/`,
  scopes: ["openid", "profile", "email", "offline_access"],
  pkce: true,
  tokenManager: {
    storage: "sessionStorage",
    secure: true,
    sameSite: "lax"
  },
};