
import React, { useContext } from "react";
import {
    GelParagraph,
    GelLink
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import styled from "@emotion/styled";
const displayPrivacyLink = () => {
    //window.location.href in new tab

    window.open("https://www.tal.com.au/Privacy-Policy", '_blank');
};
const Footer = () => {
    return (
        <StyledFooterContainer>
            <StyledContainer>
                <GelParagraph>
                    TAL Life Limited ABN 70 050 109 450 AFSL 237848 is part of the TAL Dai-ichi Life</GelParagraph>
                <GelParagraph>
                    Australia Pty Limited ABN 97 150 070 483 group of companies.
                </GelParagraph>
            </StyledContainer>
            <StyledContainerRight>
                <GelParagraph ><GelLink style={{
                    color: "#acb2b5"

                }} backgroundColorOnHover="N/A" backgroundColorOnActive="N/A"  onClick={displayPrivacyLink}>Privacy</GelLink></GelParagraph>
            </StyledContainerRight>
        </StyledFooterContainer>
    );
};

const StyledFooterContainer = styled.div`
  ${() =>
        `
    background-color: ${getGelTokens().global.themeColorBackgroundDark};
    min-height: ${getGelTokens().global.sizeBaseX18}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${getGelTokens().global.themeColorBorderDefault};
  `}
`;

const StyledContainer = styled.div`
  ${() =>
        `
    margin-left:  ${getGelTokens().global.sizeBaseX8}px;
  `}
`;

const StyledContainerRight = styled.div`
  ${() =>
        `
    margin-right:  ${getGelTokens().global.sizeBaseX8}px;
  `}
`;

export default Footer;
