import {
  GelButton,
  GelPageLayout,
  GelPageContent,
  GelPageHeader,
  GelIcon,
  GelBoxLayout,
  GelHeading4,
} from "@tal-gel/components";
import styled from "@emotion/styled";
import { getGelTokens } from "@tal-gel/theming";
import { useNavigate } from "react-router-dom";

const SubmitStatus = (props: any) => {
  const { fundContact, status, setStatus, setShowStatus } = props;
  const navigate = useNavigate();
  const backtoDashboard = () => {
    navigate("/");
  };
  const gotoUpload = () => {
    setShowStatus("none");
    setStatus("PendingDocuments");
  };
  return (
    <>
      <GelPageLayout>
        <GelPageHeader></GelPageHeader>
        <GelPageContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "stretch",
            flex: "1 0 0",
            gap: "32px",
            valign: "middle",
            background: getGelTokens().global.themeColorBackgroundLight,
            paddingTop: getGelTokens().global.gridSidenavWidth,
          }}
        >
          {status === "success" && (
            <StyledDiv>
              <StyledSuccessDiv>
                <StyledTitleDiv>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="41"
                    viewBox="0 0 40 41"
                    fill="none"
                  >
                    <rect
                      y="0.5"
                      width="40"
                      height="40"
                      rx="20"
                      fill="#E6F3D9"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.5006 30.4999C14.1806 30.4999 13.8606 30.3774 13.6169 30.1337L6.61562 23.1337C6.12813 22.6449 6.12813 21.8537 6.61562 21.3662C7.10437 20.8774 7.89562 20.8774 8.38312 21.3662L14.5006 27.4824L31.1169 10.8662C31.6056 10.3774 32.3956 10.3774 32.8844 10.8662C33.3731 11.3549 33.3731 12.1449 32.8844 12.6337L15.3844 30.1337C15.1406 30.3774 14.8206 30.4999 14.5006 30.4999Z"
                      fill="#80C342"
                    />
                    <GelIcon
                      name="check-small"
                      width={getGelTokens().global.sizeBaseUnit * 12}
                      color={getGelTokens().brand.brandColorPrimary1}
                    />
                  </svg>

                  <GelHeading4
                    style={{
                      color: getGelTokens().global.themeColorTextDefault,
                    }}
                  >
                    Your claim is successfully notified
                  </GelHeading4>
                </StyledTitleDiv>
                <StyledSuccDescDiv>
                  <p
                    style={{
                      font: "Pluto Sans",
                      display: "block",
                      color: getGelTokens().global.themeColorTextLight,
                      fontSize: getGelTokens().global.fontSizeBodyXLarge,
                      fontWeight: "400",
                      lineHeight: "22.5px",
                      letterSpacing: "-0.25px",
                    }}
                  >
                    You will need to provide some mandatory documents to lodge
                    this claim. If<br></br>you have them, you can choose to
                    upload them now. Otherwise you can<br></br>come back and
                    upload them at a later time.
                  </p>
                </StyledSuccDescDiv>
              </StyledSuccessDiv>
              <GelBoxLayout
                space="auto"
                style={{
                  paddingTop: getGelTokens().global.sizeBaseX10,
                  align: "center",
                }}
              >
                <GelButton secondary large onClick={backtoDashboard}>
                  BACK TO DASHBOARD
                </GelButton>
                <GelButton primary large onClick={gotoUpload}>
                  UPLOAD DOCUMENTS
                </GelButton>
              </GelBoxLayout>
            </StyledDiv>
          )}
          {status !== "success" && (
            <StyledDiv>
              <StyledSuccessDiv>
                <StyledTitleDiv>
                  <GelIcon
                    name="AlertCircle"
                    width={getGelTokens().global.sizeBaseUnit * 12}
                    color={getGelTokens().global.themeColorIconDanger}
                    backgroundColor={getGelTokens().global.themeColorIconDanger}
                  />
                  <GelHeading4
                    style={{
                      color: getGelTokens().global.themeColorTextDefault,
                    }}
                  >
                    Your claim could not be notified
                  </GelHeading4>
                </StyledTitleDiv>
                <StyledSuccDescDiv>
                  <p
                    style={{
                      font: "Pluto Sans",
                      display: "block",
                      color: getGelTokens().global.themeColorTextLight,
                      fontSize: getGelTokens().global.fontSizeBodyXLarge,
                      fontWeight: "400",
                      lineHeight: "22.5px",
                      letterSpacing: "-0.25px",
                    }}
                  >
                    {status === "existing-claim" && (
                      <>
                        An active claim notification already exists.
                        <br />
                        <br />
                        Please contact the Superfund on {fundContact} for
                        further assistance.
                      </>
                    )}
                    {status === "failure" && (
                      <>
                        Sorry, something went wrong.
                        <br />
                        <br />
                        Please contact the Superfund on {fundContact} for
                        further assistance.
                      </>
                    )}
                  </p>
                </StyledSuccDescDiv>
              </StyledSuccessDiv>
              <GelBoxLayout
                space="auto"
                style={{
                  paddingTop: getGelTokens().global.sizeBaseX10,
                  align: "center",
                }}
              >
                <GelButton secondary large onClick={backtoDashboard}>
                  BACK TO DASHBOARD
                </GelButton>
              </GelBoxLayout>
            </StyledDiv>
          )}
        </GelPageContent>
      </GelPageLayout>
    </>
  );
};

const StyledSuccessDiv = styled.div`
  ${() =>
    `
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    background-color: #FFFFFF;
   
  `}
`;

const StyledDiv = styled.div`
  ${() =>
    `
    valign: "middle",
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    align: center;
  `}
`;

const StyledTitleDiv = styled.div`
  ${() =>
    `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    
  `}
`;

const StyledSuccDescDiv = styled.div`
  ${() =>
    `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    
  `}
`;

export default SubmitStatus;
