import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

const RequiredAuth: React.FC = () => {
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();

  const idleTimeoutMins = parseInt(
    process.env.REACT_APP_OKTA_IDLE_TIMEOUT_MINS || "30"
  );
  const onIdle = () => {
    oktaAuth.signOut({
      postLogoutRedirectUri: window.location.origin,
    });
  };
  useIdleTimer({ onIdle, timeout: 1000 * 60 * idleTimeoutMins });

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      var operation = window.location.href
        .replace(window.location.origin, "")
        .replace("/", "");
      if (operation === "registerOrganisation")
        navigate("/registerOrganisation");

      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      // oktaAuth.signInWithRedirect();
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return <div />; // TODO: Loading state
  }

  return <Outlet />;
};

export default RequiredAuth;
