import styled from "@emotion/styled";
import { CustomUserClaims, UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { getGelTokens } from "@tal-gel/theming";
import { useEffect, useState } from "react";
import { GelLink } from "@tal-gel/components";

const AccountHeader = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [user, setUser] = useState<UserClaims<CustomUserClaims>>();
  const [initials, setInitials] = useState<string>("");

  useEffect(() => {
    const getUser = async () => {
      setUser(await oktaAuth.getUser());
    };

    if (authState?.isAuthenticated) getUser();
  }, [oktaAuth, authState?.isAuthenticated]);

  useEffect(() => {
    if (user) {
      setInitials(
        user.given_name?.substring(0, 1).toUpperCase() +
          "" +
          user.family_name?.substring(0, 1).toUpperCase()
      );
    }
  }, [user]);

  const mailForHelp = () => {
    window.location.href = "mailto:acphelp@tal.com.au";
  };

  return (
    <>
      <StyledHeaderContainer>
        <StyledImageContainer>
          <img aria-label="TAL-Logo" src="/logo-primary.svg"></img>
        </StyledImageContainer>
        <div className="header-links">
          <span>
            For help, email{" "}
            <GelLink onClick={mailForHelp}>acphelp@tal.com.au</GelLink>
          </span>{" "}
        </div>
      </StyledHeaderContainer>
    </>
  );
};

const StyledHeaderContainer = styled.div`
  ${() =>
    `
    background-color: ${getGelTokens().global.themeColorBackgroundDefault};
    min-height: ${getGelTokens().global.sizeBaseX18}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${getGelTokens().global.themeColorBorderDefault};
  `}
`;

const StyledImageContainer = styled.div`
  ${() =>
    `
    margin-left:  ${getGelTokens().global.sizeBaseX8}px;
  `}
`;

export default AccountHeader;
