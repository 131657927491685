import {
  GelHeading6,
  GelLabel,
  GelParagraph,
  GelButton,
  GelIcon,
  GelModal,
  GelProgressStatus,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { useState } from "react";
import Uploader from "../../components/layout/Uploader";
import FileTypes from "./FileTypes";

interface MandatoryDocumentsProps {
  lodgement: any;
  setLodgement: (lodgement: any) => any;
  hasNoUploadedFilesError: boolean;
  setHasNoUploadedFilesError: any;
  setIsUploading?: (isUploading: boolean) => void;
}

const MandatoryDocuments = (props: MandatoryDocumentsProps) => {
  const {
    lodgement,
    setLodgement,
    setHasNoUploadedFilesError,
    setIsUploading,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  return (
    <div
      style={{
        paddingTop: getGelTokens().global.sizeBaseX4,
      }}
    >
      <GelHeading6>Documents to help us get started</GelHeading6>
      <div style={{ display: "flex" }}>
        <GelParagraph style={{ paddingRight: "10px" }}>
          Maximum file size: 150 MB per file.
        </GelParagraph>
        <GelButton tertiary small onClick={() => setOpenModal(true)}>
          View all supported file types
        </GelButton>
      </div>
      <GelModal
        width="sm"
        height={getGelTokens().global.sizeBaseUnit * 80}
        open={openModal}
        onClose={() => setOpenModal(false)}
        sticky
        title="Supported file types"
      >
        <FileTypes></FileTypes>
      </GelModal>
      <div
        style={{
          paddingRight: getGelTokens().global.sizeBaseX4,
          paddingTop: getGelTokens().global.sizeBaseX4,
        }}
      >
        <div
          style={{
            paddingTop: getGelTokens().global.sizeBaseX4,
            border: "1px solid" + getGelTokens().global.themeColorBorderDefault,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              paddingLeft: getGelTokens().global.sizeBaseX4,
              paddingRight: getGelTokens().global.sizeBaseX4,
              paddingBottom: getGelTokens().global.sizeBaseX4,
              borderBottom:
                "1px solid" + getGelTokens().global.themeColorBorderDefault,
            }}
          >
            <GelProgressStatus stepNum={1} active></GelProgressStatus>
            <GelLabel
              style={{
                paddingBottom: getGelTokens().global.sizeBaseX1,
              }}
            >
              Claim form
            </GelLabel>
          </div>
          <div
            style={{
              paddingTop: getGelTokens().global.sizeBaseX4,
              paddingLeft: getGelTokens().global.sizeBaseX4,
              paddingRight: getGelTokens().global.sizeBaseX4,
            }}
          >
            <Uploader
              lodgement={lodgement}
              setLodgement={setLodgement}
              type="claim"
              hasNoUploadedFilesError={props.hasNoUploadedFilesError}
              setHasNoUploadedFilesError={setHasNoUploadedFilesError}
              setIsUploading={setIsUploading}
            />
          </div>
        </div>
        <div
          style={{
            paddingTop: getGelTokens().global.sizeBaseX4,
            marginTop: getGelTokens().global.sizeBaseX4,
            border: "1px solid" + getGelTokens().global.themeColorBorderDefault,
          }}
        >
          <div
            style={{
              width: "100%",
              alignItems: "center",
              display: "flex",
              paddingLeft: getGelTokens().global.sizeBaseX4,
              paddingRight: getGelTokens().global.sizeBaseX4,
              paddingBottom: getGelTokens().global.sizeBaseX4,
              borderBottom:
                "1px solid" + getGelTokens().global.themeColorBorderDefault,
            }}
          >
            <GelProgressStatus stepNum={2} active></GelProgressStatus>

            <GelLabel>Medical documents</GelLabel>
          </div>
          <GelParagraph
            style={{
              paddingTop: getGelTokens().global.sizeBaseX4,
              paddingLeft: getGelTokens().global.sizeBaseX4,
              paddingRight: getGelTokens().global.sizeBaseX4,
            }}
          >
            Medical documents may include but is not limited to:
          </GelParagraph>
          <div
            style={{
              display: "flex",
              paddingTop: getGelTokens().global.sizeBaseX4,
            }}
          >
            <GelIcon
              color={getGelTokens().global.themeColorIconDefault}
              name="Bullet"
              width={getGelTokens().global.sizeBaseX4}
              height={getGelTokens().global.sizeBaseX4}
              style={{
                paddingTop: "2px",
                paddingLeft: getGelTokens().global.sizeBaseX4,
                paddingRight: getGelTokens().global.sizeBaseX4,
              }}
            />
            <GelParagraph>Attending Doctor’s Statement</GelParagraph>
          </div>
          <div style={{ display: "flex" }}>
            <GelIcon
              color={getGelTokens().global.themeColorIconDefault}
              name="Bullet"
              width={getGelTokens().global.sizeBaseX4}
              height={getGelTokens().global.sizeBaseX4}
              style={{
                paddingTop: "2px",
                paddingLeft: getGelTokens().global.sizeBaseX4,
                paddingRight: getGelTokens().global.sizeBaseX4,
              }}
            />
            <GelParagraph>Hospital Discharge summary</GelParagraph>
          </div>
          <div style={{ display: "flex" }}>
            <GelIcon
              color={getGelTokens().global.themeColorIconDefault}
              name="Bullet"
              width={getGelTokens().global.sizeBaseX4}
              height={getGelTokens().global.sizeBaseX4}
              style={{
                paddingTop: "2px",
                paddingLeft: getGelTokens().global.sizeBaseX4,
                paddingRight: getGelTokens().global.sizeBaseX4,
              }}
            />
            <GelParagraph>
              WorkCover medical certificate/medical file from other insurance or
              government scheme such as Workers Compensation, CTP, TAC, MAIB,
              Centrelink or DVA
            </GelParagraph>
          </div>
          <div style={{ display: "flex" }}>
            <GelIcon
              color={getGelTokens().global.themeColorIconDefault}
              name="Bullet"
              width={getGelTokens().global.sizeBaseX4}
              height={getGelTokens().global.sizeBaseX4}
              style={{
                paddingTop: "2px",
                paddingLeft: getGelTokens().global.sizeBaseX4,
                paddingRight: getGelTokens().global.sizeBaseX4,
              }}
            />
            <GelParagraph>
              Medical test results, scans/imaging or pathology reports (e.g
              x-rays, CT scans, Ultrasound, histopathology);
            </GelParagraph>
          </div>
          <div style={{ display: "flex" }}>
            <GelIcon
              color={getGelTokens().global.themeColorIconDefault}
              name="Bullet"
              width={getGelTokens().global.sizeBaseX4}
              height={getGelTokens().global.sizeBaseX4}
              style={{
                paddingTop: "2px",
                paddingLeft: getGelTokens().global.sizeBaseX4,
                paddingRight: getGelTokens().global.sizeBaseX4,
              }}
            />
            <GelParagraph>
              Medical reports of a doctor, specialist, or allied health
              professional (ie. GP, Psychiatrist, Psychologist, Physiotherapist,
              Chiropractor).
            </GelParagraph>
          </div>
          <div
            style={{
              paddingTop: getGelTokens().global.sizeBaseX4,
              paddingLeft: getGelTokens().global.sizeBaseX4,
              paddingRight: getGelTokens().global.sizeBaseX4,
            }}
          >
            <Uploader
              lodgement={lodgement}
              setLodgement={setLodgement}
              type="medical"
              setIsUploading={setIsUploading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MandatoryDocuments;
