import { ReactNode, createContext, useState } from "react";
import React from "react";

type Props = {
  children?: ReactNode;
};

export type UserSessionContext = {
  encryptedUserSession: string;
};

type IUserSessionContext = {
  userSessionContextData: UserSessionContext;

  setUserSessionContext: (userSessionContextData: UserSessionContext) => void;
};

const initialValue = {
  userSessionContextData: {
    encryptedUserSession: "",
  },
  setUserSessionContext: () => {},
};

const UserSessionContext = createContext<IUserSessionContext>(initialValue);

const UserSessionContextProvider = ({ children }: Props) => {
  const [userSessionContextData, setUserSessionContext] = useState(
    initialValue.userSessionContextData
  );

  return (
    <UserSessionContext.Provider
      value={{ userSessionContextData, setUserSessionContext }}
    >
      {children}
    </UserSessionContext.Provider>
  );
};

export { UserSessionContext, UserSessionContextProvider };
