import {
  GelButton,
  GelHeading3,
  GelPageLayout,
  GelPageContent,
  GelPageHeader,
  GelTag,
  GelSpinner,
  GelIcon,
  GelModal,
  GelParagraph,
  GelBoxLayout,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridOverlay } from "@mui/x-data-grid";
import { useOktaAuth } from "@okta/okta-react";

import { CustomUserClaims, UserClaims, decodeToken } from "@okta/okta-auth-js";
import { UserContext } from "../../common/usercontext/user.context";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { oktaAuth, authState } = useOktaAuth();
  const [showTermsAndCondtions, setTermsAndCondtions] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [user, setUser] = useState<UserClaims<CustomUserClaims>>();

  const { contextData, setUserContext } = useContext(UserContext);

  const navigate = useNavigate();
  const onStartANewClaim = () => {
    navigate("/lodgement");
  };

  const manageUsers = () => {
    if (contextData.userType === "DB69DAA7-224D-4D81-8ECC-F9DFD5A0A4B7")
      navigate("/manageUsers");
  };

  const [lodgements, setLodgements] = useState([] as any[]);
  const [statuses, setStatuses] = useState([] as any[]);

  const getStatusTag = (status: any) => {
    switch (status) {
      case "PendingDetails":
        return <GelTag warning>Pending Details</GelTag>; // Orange color for Pending status
      case "PendingDocuments":
        return <GelTag warning>Pending Documents</GelTag>; // Green color for Completed status
      case "PendingEligibiltyChecks":
        return <GelTag info>Pending Eligibility Checks</GelTag>; // Red color for Cancelled status
      case "Submitted":
        return <GelTag success>Submitted</GelTag>; // Red color for Cancelled status
      case "ContactFund":
        return <GelTag danger>Contact Fund</GelTag>; // Red color for Cancelled status
      default:
        return <></>; // Orange color for Pending status
    }
  };

  const saveUserQuery = `
    mutation SaveUserTAndC($saveUserRequest: SaveUserRequestInput!) {
      saveUserTAndC(saveUserRequest: $saveUserRequest) {
        oktaUserId,
        tAndCAccepted
    }
  }`;

  const onOk = async () => {
    setButtonDisabled(true);
    setLoading(true);
    const variables = {
      saveUserRequest: {
        requestOperation: "Add",
        user: {
          oktaUserId: user?.sub,
          tAndCAccepted: true,
        },
      },
    };
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/graphql/`, {
        query: saveUserQuery,
        variables,
      });
    } catch (error) {
      console.log(error);
      navigate("/error");
    } finally {
      setLoading(false);
      setButtonDisabled(false);
    }
    setTermsAccepted(true);
    setTermsAndCondtions(false);
  };

  const onCancel = () => {
    setTermsAndCondtions(false);
    oktaAuth.signOut();
  };

  const getStatusGelTag = (status: any) => {
    return (
      <GelTag {...{ [status.statusType]: true }}>{status.statusName}</GelTag>
    );
  };

  const showResumeButton = (params: any) => {
    if (
      params.row["status"] === "PendingDetails" ||
      params.row["status"] === "PendingDocuments"
    ) {
      return (
        <GelButton
          data-testid={params.row["name"]}
          tertiary
          small
          onClick={() => {
            navigate(`/lodgement/${params.row["lodgementId"]}`);
          }}
        >
          Resume
        </GelButton>
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Account / Member No. (Fund)",
      minWidth: 300,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      renderCell: (params: any) => (
        <div>
          {params.row["pbriId"] && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  fontFamily: "Pluto Sans",
                }}
              >
                {params.row["pbriId"]}
              </div>
            </div>
          )}
          {params.row["awareId"] && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  fontFamily: "Pluto Sans",
                }}
              >
                {params.row["awareId"]}
              </div>
              <div style={{ marginLeft: "5px", marginBottom: "5px" }}>
                (Aware)
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Member's Name",
      minWidth: 250,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      renderCell: (params: any) => (
        <div
          style={{
            fontFamily: "Pluto Sans",
          }}
        >
          {params.row["name"]}
        </div>
      ),
    },
    {
      field: "date",
      headerName: "Date Started",
      minWidth: 150,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      minWidth: 200,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      renderCell: (params: any) => {
        return getStatusTag(params.row["status"]);
      },
      renderHeader: (params: any) => {
        return (
          <div>
            <strong style={{ display: "inline" }}>Status </strong>
            <GelIcon
              id="statusIcon"
              name="AlertCircle"
              width={getGelTokens().global.sizeBaseUnit * 4}
              top={3}
              data-testid="open-modal-button"
              onClick={(event: any) => {
                event.stopPropagation();
                setOpenModal(true);
              }}
              style={{ display: "inline" }}
            />
          </div>
        );
      },
    },
    {
      field: "benefit",
      headerName: "Reference / Claim No. (Benefit)",
      minWidth: 300,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      renderCell: (params: any) => {
        return (
          <div>
            {params.row["benefit"]?.map((number: any, index: any) => (
              <div
                style={{
                  fontFamily: "Pluto Sans",
                }}
              >
                {number}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      renderCell: (params: any) => {
        return showResumeButton(params);
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    const getUserByUserId = async () => {
      try {
        const fetchedUser = await oktaAuth.getUser();
        setUser(fetchedUser);
        await fetchUser(fetchedUser);
        const accessToken = await oktaAuth.getAccessToken();
        if (accessToken && fetchedUser) {
          const decodedToken = decodeToken(accessToken);
          const payload = decodedToken.payload;

          setUserContext({
            factorId: "",
            stateToken: "",
            mobileNumber: "",
            emailAddress: fetchedUser.email ? fetchedUser.email : "",
            organisation: payload.organization.toString(),
            userId: fetchedUser.sub,
            userType: payload.UserType?.toString(),
          });
        }
      } catch (error) {
        navigate("/error");
      } finally {
        setLoading(false);
      }
    };

    getUserByUserId();
  }, []);

  const fetchUser = async (user: any) => {
    try {
      const userId = user.sub;
      const query = `query {
        userTandCByUserId(userId: "${userId}") {
          userId,
          tAndCAccepted
        }
      }`;
      const tandcResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
        { query }
      );
      const hasUser = tandcResponse.data.data.userTandCByUserId !== null;
      setTermsAndCondtions(!hasUser);
      if (hasUser) {
        setTermsAndCondtions(
          !tandcResponse.data.data.userTandCByUserId.tAndCAccepted
        );
        setTermsAccepted(
          tandcResponse.data.data.userTandCByUserId.tAndCAccepted
        );
      }
    } catch (error) {
      console.error("Error fetching T&C:", error);
      navigate("/error");
    }
  };

  useEffect(() => {
    if (termsAccepted) {
      const fetchStatus = async () => {
        try {
          const statusResponse = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
            {
              query: `query {
                        status {
                          statusId,
                          statusName,
                          statusDescription,
                          statusType
                        }
                      }`,
            }
          );
          setStatuses(statusResponse.data.data.status);
        } catch {
          navigate("/error");
        }
      };

      fetchStatus();
    }
  }, [termsAccepted]);

  useEffect(() => {
    if (termsAccepted) {
      setLoading(true);
      const loadLodgements = async () => {
        try {
          const existingLodgementsResponse = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
            {
              query: `query {
                        lodgements {
                          lodgementId,
                          createdAt,
                          memberDetails{
                            firstName,
                            lastName,
                            memberNumber,
                            awareMemberNumber,
                            fund {
                              fundId,
                              fundName,
                              fundAlias
                            }
                          },
                          benefitDetails{
                            lastDayOfWork
                            ippCheckBox,
                            tpdpCheckBox,
                            tipCheckBox,
                            tpdaCheckBox,
                            tiaCheckBox
                          },
                          claims {
                            ghqClaimId,
                            fineosClaimNumber,
                            benefitType,
                            fund {
                              fundId,
                              fundName,
                              fundAlias
                            }
                          },
                          status
                        }
                      }`,
            }
          );
          setLodgements(existingLodgementsResponse.data.data.lodgements);
        } catch {
          navigate("/error");
        } finally {
          setLoading(false);
        }
      };
      loadLodgements();
    }
  }, [termsAccepted]);

  function CustomNoRowsOverlay() {
    if (loading) {
      return null; // Return null when loading is true to display nothing
    }
    return (
      <GridOverlay>
        <div
          style={{
            textAlign: "center",
            fontFamily: "Pluto Sans",
            fontWeight: 700,
          }}
        >
          <div style={{ paddingBottom: "10px" }}>There are no claims yet.</div>
          <div>Click on 'Start A New Claim' to create one.</div>
        </div>
      </GridOverlay>
    );
  }

  const displayLodgements = lodgements.map((lodgement) => {
    const fullName =
      lodgement?.memberDetails?.firstName +
      " " +
      lodgement?.memberDetails?.lastName;

    const findBenefitType = (benefitType: any, fundName: any) => {
      return ` (${benefitType} - ${fundName})`;
    };

    const claimNumbers = lodgement?.claims?.map((claim: any) =>
      claim.fineosClaimNumber
        ? `${claim.fineosClaimNumber}${findBenefitType(
            claim.benefitType,
            claim.fund?.fundAlias
          )}`
        : claim.ghqClaimId
        ? `GHQ_${claim.ghqClaimId}${findBenefitType(
            claim.benefitType,
            claim.fund?.fundAlias
          )}`
        : ""
    );

    const date = () => {
      var dateParts = lodgement?.createdAt.substring(0, 10).split("-");
      return dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
    };

    return {
      id: lodgement?.lodgementId,
      pbriId:
        lodgement?.memberDetails?.memberNumber +
        " (" +
        lodgement?.memberDetails?.fund?.fundAlias +
        ")",
      awareId: lodgement?.memberDetails?.awareMemberNumber,
      name: fullName,
      date: date(),
      status: lodgement?.status,
      benefit: claimNumbers,
      lodgementId: lodgement?.lodgementId,
    };
  });

  return (
    <>
      <GelPageLayout>
        <GelPageHeader>
          <Header />
        </GelPageHeader>
        <GelPageContent>
          <StyledDashboardContainer>
            <GelHeading3>Claims dashboard</GelHeading3>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <GelButton
                style={{ top: "40px", marginRight: "20px" }}
                primary
                large
                onClick={onStartANewClaim}
              >
                Start a new claim
              </GelButton>
              {contextData.userType ===
                "DB69DAA7-224D-4D81-8ECC-F9DFD5A0A4B7" && (
                <GelButton
                  style={{ top: "40px", marginRight: "20px" }}
                  primary
                  large
                  onClick={manageUsers}
                >
                  Manage users
                </GelButton>
              )}
            </div>

            {loading && <GelSpinner large overlay />}
            <Box
              sx={{
                paddingTop: "90px",
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#EAECED",
                  fontFamily: "Pluto Sans",
                  fontWeight: 700,
                },
              }}
            >
              <DataGrid
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                disableRowSelectionOnClick
                rows={displayLodgements}
                columns={columns}
                autoHeight={true}
                getRowHeight={() => "auto"}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 20,
                    },
                  },
                }}
              />
            </Box>
            <GelModal
              width="sm"
              open={openModal}
              onClose={() => setOpenModal(false)}
            >
              <div>
                {statuses.map((status, index) => (
                  <>
                    {getStatusGelTag(status)}
                    <GelParagraph>{status.statusDescription}</GelParagraph>
                    <br />
                  </>
                ))}
              </div>
            </GelModal>
            {showTermsAndCondtions && (
              <GelModal
                width={getGelTokens().global.sizeBaseUnit * 200}
                open={showTermsAndCondtions}
                onClose={() => {
                  setTermsAndCondtions(false);
                  oktaAuth.signOut();
                }}
                closeOnBackdropClick={false}
                action={
                  <GelBoxLayout gap="small" space="auto">
                    <GelButton
                      secondary
                      medium
                      width={getGelTokens().global.sizeBaseUnit * 22}
                      onClick={onCancel}
                    >
                      Disagree
                    </GelButton>

                    <GelButton
                      primary
                      medium
                      width={getGelTokens().global.sizeBaseUnit * 22}
                      onClick={onOk}
                      disabled={buttonDisabled}
                    >
                      Agree
                    </GelButton>
                  </GelBoxLayout>
                }
              >
                <div>
                  <StyledHeaderText>
                    Third-Party Portal - Terms and Conditions
                  </StyledHeaderText>
                  <StyledContainer>
                    <StyledSubHeaderNumberText>1.</StyledSubHeaderNumberText>
                    <StyledSubHeaderText>Overview</StyledSubHeaderText>
                  </StyledContainer>
                  <StyledContainer>
                    <StyledParagraphNumber>1.1.</StyledParagraphNumber>
                    <StyledParagraph>
                      The Third-Party Portal (<b>TPP</b>) is a secure online
                      service that provides an efficient and effective
                    </StyledParagraph>
                    <StyledParagraphSub>
                      method for an individual or organisation (
                      <b>Representative, You, Yours, Yourself</b>) to submit a
                    </StyledParagraphSub>
                    <StyledParagraphSub>
                      claim on behalf of members or employees of participating
                      superannuation funds, corporations,
                    </StyledParagraphSub>
                    <StyledParagraphSub>
                      employer groups or master trusts who have a group life
                      insurance arrangement (<b>Claimants</b>) with
                    </StyledParagraphSub>
                    <StyledParagraphSub>
                      TAL Life Limited ABN 70 050 109 450 AFSL 237 848 (
                      <b>TAL, We, Us, Ours</b>).
                    </StyledParagraphSub>
                  </StyledContainer>
                  <StyledContainer>
                    <StyledSubHeaderNumberText>2.</StyledSubHeaderNumberText>
                    <StyledSubHeaderText>Application</StyledSubHeaderText>
                  </StyledContainer>
                  <StyledContainer>
                    <StyledParagraphNumber>2.1.</StyledParagraphNumber>
                    <StyledParagraph>
                      These Terms are a contract between You and Us commencing
                      on and from the date We approve
                    </StyledParagraph>
                    <StyledParagraphSub>
                      Your access to the TPP (<b>Terms</b>). The contract is
                      terminated immediately if We withdraw Your
                    </StyledParagraphSub>
                    <StyledParagraphSub>
                      approval to use TPP or You voluntarily withdraw from using
                      the TPP.
                    </StyledParagraphSub>
                  </StyledContainer>
                  <StyledSubContainer>
                    <StyledParagraphNumber>2.2.</StyledParagraphNumber>
                    <StyledSubParagraph>
                      We make the TPP available to You as consideration for Your
                      compliance with these Terms.
                    </StyledSubParagraph>
                  </StyledSubContainer>
                  <StyledSubContainer>
                    <StyledParagraphNumber>2.3.</StyledParagraphNumber>
                    <StyledSubParagraph>
                      Approval for use of the TPP is subject to Our discretion.
                    </StyledSubParagraph>
                  </StyledSubContainer>
                  <StyledSubContainer>
                    <StyledParagraphNumber>2.4.</StyledParagraphNumber>
                    <StyledSubParagraph>
                      Clause 5 survives termination of the contract.
                    </StyledSubParagraph>
                  </StyledSubContainer>
                  <StyledContainer>
                    <StyledSubHeaderNumberText>3.</StyledSubHeaderNumberText>
                    <StyledSubHeaderText>
                      System availability
                    </StyledSubHeaderText>
                  </StyledContainer>
                  <StyledContainer>
                    <StyledParagraphNumber>3.1.</StyledParagraphNumber>
                    <StyledParagraph>
                      To the fullest extent permissible by law, the content on
                      the TPP is provided as is and without
                    </StyledParagraph>
                    <StyledParagraphSub>
                      warranty of any kind (either express or implied). We do
                      not warrant that the TPP or its contents
                    </StyledParagraphSub>
                    <StyledParagraphSub>
                      will be error and/or omission free or provided
                      uninterrupted. Nor will We be liable for errors
                    </StyledParagraphSub>
                    <StyledParagraphSub>
                      and/or omissions or interruptions to the website or for
                      loss or damage suffered as a result of
                    </StyledParagraphSub>
                    <StyledParagraphSub>
                      reliance on any information or document available on the
                      TPP.
                    </StyledParagraphSub>
                  </StyledContainer>
                  <StyledSubContainer>
                    <StyledParagraphNumber>3.2.</StyledParagraphNumber>
                    <StyledSubParagraph>
                      To the fullest extent permissible by law, TAL specifically
                      disclaims and excludes any, and all
                    </StyledSubParagraph>
                    <StyledParagraphSub>
                      liability that may arise for direct, indirect, incidental,
                      consequential, loss of profit or special
                    </StyledParagraphSub>
                    <StyledParagraphSub>
                      damages that may arise from the access to, reliance on
                      and/or use of the TPP or its contents
                    </StyledParagraphSub>
                    <StyledParagraphSub>thereof.</StyledParagraphSub>
                  </StyledSubContainer>
                  <StyledContainer>
                    <StyledSubHeaderNumberText>4.</StyledSubHeaderNumberText>
                    <StyledSubHeaderText>
                      Conduct of the Representative
                    </StyledSubHeaderText>
                  </StyledContainer>
                  <StyledContainer>
                    <StyledParagraphNumber>4.1.</StyledParagraphNumber>
                    <StyledParagraph>
                      You are responsible for the actions of every person that
                      accesses the TPP using the Your users’
                    </StyledParagraph>
                    <StyledParagraphSub>
                      access credentials, whether or not that person is acting
                      in the course of their duties on behalf of
                    </StyledParagraphSub>
                    <StyledParagraphSub>the Representative.</StyledParagraphSub>
                  </StyledContainer>
                  <StyledSubContainer>
                    <StyledParagraphNumber>4.2.</StyledParagraphNumber>
                    <StyledSubParagraph>
                      You must not allow another organisation to use Your access
                      users’ access credentials to the TPP.
                    </StyledSubParagraph>
                  </StyledSubContainer>
                  <StyledSubContainer>
                    <StyledParagraphNumber>4.3.</StyledParagraphNumber>
                    <StyledSubParagraph>
                      We may suspend access or withdraw Your access to the TPP
                      at any time if, in Our opinion, You
                    </StyledSubParagraph>
                    <StyledParagraphSub>
                      may be or are no longer suitable to access the TPP.
                    </StyledParagraphSub>
                  </StyledSubContainer>
                  <StyledSubContainer>
                    <StyledParagraphNumber>4.4.</StyledParagraphNumber>
                    <StyledSubParagraph>
                      You immediately notify TAL if any of Your specified
                      personnel are no longer authorised to use
                    </StyledSubParagraph>
                    <StyledParagraphSub>
                      TPP on behalf of the Representative.
                    </StyledParagraphSub>
                  </StyledSubContainer>
                  <StyledContainer>
                    <StyledSubHeaderNumberText>5.</StyledSubHeaderNumberText>
                    <StyledSubHeaderText>
                      Intellectual Property
                    </StyledSubHeaderText>
                  </StyledContainer>
                  <StyledContainer>
                    <StyledParagraphNumber>5.1.</StyledParagraphNumber>
                    <StyledParagraph>
                      We may provide materials to You to enable You to access
                      and use the TPP including (but not
                    </StyledParagraph>
                    <StyledParagraphSub>
                      limited to): software; technical specification documents
                      including private algorithms, access
                    </StyledParagraphSub>
                    <StyledParagraphSub>
                      credentials or secret keys; equipment; goods; information
                      or data stored by any means{" "}
                    </StyledParagraphSub>
                    <StyledParagraphSub>
                      <b>(Material).</b>
                    </StyledParagraphSub>
                  </StyledContainer>
                  <StyledSubContainer>
                    <StyledParagraphNumber>5.2.</StyledParagraphNumber>
                    <StyledSubParagraph>
                      If We make any Material available to You, You agree that:
                    </StyledSubParagraph>
                    <InlineContainer>
                      <StyledParagraphBulletFirst>
                        a. &nbsp;&nbsp;&nbsp;&nbsp;ownership of intellectual
                        property rights in that material remains with Us at all
                        times;
                      </StyledParagraphBulletFirst>
                      <StyledParagraphBulletFirst>
                        b. &nbsp;&nbsp;&nbsp;&nbsp;You will use that Material
                        solely for the purpose of accessing and using TPP;
                      </StyledParagraphBulletFirst>
                      <StyledParagraphBulletFirst>
                        c. &nbsp;&nbsp;&nbsp;&nbsp;You must not modify, de
                        compile, exploit for commercial use or customise the
                        Material;
                      </StyledParagraphBulletFirst>
                      <StyledParagraphBulletFirst>
                        d. &nbsp;&nbsp;&nbsp;&nbsp;You must not sub-license any
                        of the Material; and
                      </StyledParagraphBulletFirst>
                      <StyledParagraphBulletFirst>
                        e. &nbsp;&nbsp;&nbsp;&nbsp;You accept full
                        responsibility and indemnify TAL for any expense, loss
                        or liability that TAL
                      </StyledParagraphBulletFirst>
                      <StyledParagraphBulletFirst>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;incurs
                        as a result the Your use of the Material on the TPP that
                        is not expressly authorised
                      </StyledParagraphBulletFirst>
                      <StyledParagraphBulletFirst>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;by
                        TAL.
                      </StyledParagraphBulletFirst>
                    </InlineContainer>
                  </StyledSubContainer>
                  <StyledSubContainer>
                    <StyledParagraphNumber>5.3.</StyledParagraphNumber>
                    <StyledSubParagraph>
                      We may, at any time, require You to return, delete,
                      destroy or otherwise cease using any
                    </StyledSubParagraph>
                    <StyledParagraphSub>
                      Material provided by Us.
                    </StyledParagraphSub>
                  </StyledSubContainer>
                  <StyledSubContainer>
                    <StyledParagraphNumber>5.4.</StyledParagraphNumber>
                    <StyledSubParagraph>
                      Upon termination of these Terms, You must immediately
                      return to Us or securely delete from
                    </StyledSubParagraph>
                    <StyledParagraphSub>
                      any system or documentation the TPP Material that has been
                      incorporated as applicable.
                    </StyledParagraphSub>
                    <StyledParaStart>
                      <b>
                        You must comply with any directions given to You by Us
                        in relation to any Material provided by Us.
                      </b>
                    </StyledParaStart>
                    <StyledParaLink>
                      http://www.tal.com.au/Privacy-Policy
                    </StyledParaLink>
                  </StyledSubContainer>
                  <StyledContainer>
                    <StyledSubHeaderNumberText>6.</StyledSubHeaderNumberText>
                    <StyledSubHeaderText>Privacy</StyledSubHeaderText>
                  </StyledContainer>
                  <StyledContainer>
                    <StyledParaText>
                      TAL and its related entities (<b>TAL Group</b>) are
                      committed to ensuring that your information is handled
                    </StyledParaText>
                    <StyledParaSubText>
                      responsibly in accordance with the Privacy laws, including
                      the Privacy Act 1988 (Cth) and the Australian
                    </StyledParaSubText>
                    <StyledParaSubText>
                      Privacy Principles. The way in which TAL collects, uses,
                      secures and discloses your information is set out
                    </StyledParaSubText>
                    <StyledParaSubText>
                      in the TAL Privacy Policy available at &nbsp;
                      <StyledLink>
                        http://www.tal.com.au/Privacy-Policy
                      </StyledLink>
                      . We collect personal
                    </StyledParaSubText>
                    <StyledParaSubText>
                      information, including, but not limited to, your name and
                      email address, so that We can set up your user
                    </StyledParaSubText>
                    <StyledParaSubText>
                      credentials to access the TPP if approved by TAL.
                    </StyledParaSubText>
                  </StyledContainer>
                  <StyledContainer>
                    <StyledSubHeaderNumberText>7.</StyledSubHeaderNumberText>
                    <StyledSubHeaderText>Cookies</StyledSubHeaderText>
                  </StyledContainer>
                  <StyledContainer>
                    <StyledParaText>
                      Cookies are pieces of information that a website transfers
                      to your computer's hard disk for record
                    </StyledParaText>
                    <StyledParaSubText>
                      keeping purposes. Most web browsers are set to accept
                      cookies. We use cookies to make Your use of
                    </StyledParaSubText>
                    <StyledParaSubText>
                      Our website and services as convenient as possible.
                      Cookies may identify a user's browser and are useful
                    </StyledParaSubText>
                    <StyledParaSubText>
                      to estimate the number of customers and determine overall
                      traffic patterns through our website. If You
                    </StyledParaSubText>
                    <StyledParaSubText>
                      do not wish to receive any cookies, You may set your
                      browser to refuse cookies. Please refer to the TAL
                    </StyledParaSubText>
                    <StyledParaSubText>
                      Group Privacy Policy for more information as outlined in
                      section 6.
                    </StyledParaSubText>
                  </StyledContainer>

                  <StyledContainer>
                    <StyledSubHeaderNumberText>8.</StyledSubHeaderNumberText>
                    <StyledSubHeaderText>
                      Variation to Terms
                    </StyledSubHeaderText>
                  </StyledContainer>
                  <StyledContainer>
                    <StyledParagraphNumber>8.1.</StyledParagraphNumber>
                    <StyledParagraph>
                      We may, from time to time, vary these Terms and publish
                      the varied Terms on the TPP
                    </StyledParagraph>
                    <StyledParagraphSub>
                      or providing the varied Terms in writing to You.
                    </StyledParagraphSub>
                  </StyledContainer>
                  <StyledSubContainer>
                    <StyledParagraphNumber>8.2.</StyledParagraphNumber>
                    <StyledSubParagraph>
                      You are responsible for informing Yourself of any
                      variations to these Terms before accessing the
                    </StyledSubParagraph>
                    <StyledParagraphSub>TPP.</StyledParagraphSub>
                  </StyledSubContainer>
                  <StyledSubContainer>
                    <StyledParagraphNumber>8.3.</StyledParagraphNumber>
                    <StyledSubParagraph>
                      You must, if You do not agree to comply with those varied
                      Terms, immediately notify Us that
                    </StyledSubParagraph>
                    <StyledParagraphSub>
                      You from using the TPP.
                    </StyledParagraphSub>
                  </StyledSubContainer>
                  <StyledSubContainer>
                    <StyledParagraphNumber>8.4.</StyledParagraphNumber>
                    <StyledSubParagraph>
                      You will be taken to have agreed to the varied Terms if
                      you do not withdraw from using the TPP
                    </StyledSubParagraph>
                    <StyledParagraphSub>
                      and continue to access TPP after the Terms are varied.
                    </StyledParagraphSub>
                  </StyledSubContainer>
                  <StyledContainer>
                    <StyledSubHeaderNumberText>9.</StyledSubHeaderNumberText>
                    <StyledSubHeaderText>
                      Other important information
                    </StyledSubHeaderText>
                  </StyledContainer>
                  <StyledContainer>
                    <StyledParaText>
                      If any of these Terms are found to be invalid or
                      unenforceable at law, that invalidity or unenforceability
                    </StyledParaText>
                    <StyledParaSubText>
                      will not affect the remainder of the Terms, which will
                      continue in full force and effect and to which the
                    </StyledParaSubText>
                    <StyledParaSubText>You will be bound.</StyledParaSubText>
                    <StyledParaText>
                      TAL Life Limited ABN 70 050 109 450 AFSL 237848 is part of
                      the TAL Dai-ichi Life Australia Pty Limited
                    </StyledParaText>
                    <StyledParaSubText>
                      ABN 97 150 070 483 group of companies. The different
                      entities of TAL group of companies are not
                    </StyledParaSubText>
                    <StyledParaSubText>
                      responsible for, or liable in respect of, products and
                      services provided by the other.
                    </StyledParaSubText>
                  </StyledContainer>

                  <StyledContainer>
                    <StyledSubHeaderNumberText>10.</StyledSubHeaderNumberText>
                    <StyledSubHeaderText>Governing law</StyledSubHeaderText>
                  </StyledContainer>
                  <StyledContainer>
                    <StyledParaText>
                      This agreement is governed by the law in force in the
                      state of NSW and the parties submit to the non-
                    </StyledParaText>
                    <StyledParaSubText>
                      exclusive jurisdiction of the courts of that place.
                    </StyledParaSubText>
                    <StyledParaTextParticipant>
                      <b>Participant Acknowledgement:</b>
                    </StyledParaTextParticipant>
                    <StyledParaText>
                      By clicking on agree, I, the Representative, confirm that
                      I have read and agree to the Terms and
                    </StyledParaText>
                    <StyledParaSubText>Conditions.</StyledParaSubText>
                  </StyledContainer>
                </div>
              </GelModal>
            )}
          </StyledDashboardContainer>
        </GelPageContent>
      </GelPageLayout>
    </>
  );
};

const InlineContainer = styled.div`
  display: inline;
`;

const StyledDashboardContainer = styled.div`
  ${() =>
    `
    float: left;
    margin-left:  202px;
    margin-top:  40px;
  `}
`;

const StyledContainer = styled.div`
  padding-top: ${getGelTokens().global.sizeBaseX6}px;
`;

const StyledSubContainer = styled.div`
  padding-top: ${getGelTokens().global.sizeBaseX2}px;
`;

export const StyledHeaderText = styled.span(() => ({
  color: getGelTokens().global.themeColorInteractive1Active,
  fontSize: `${getGelTokens().global.fontSizeHeadingMedium}px`,
  fontFamily: getGelTokens().global.fontFamilySans,
  textDecoration: "underline",
  fontWeight: getGelTokens().global.fontWeightBold,
}));

export const StyledSubHeaderNumberText = styled.span(() => ({
  color: getGelTokens().global.themeColorInteractive1Active,
  fontSize: `${getGelTokens().global.fontSizeHeadingMedium}px`,
  fontFamily: getGelTokens().global.fontFamilySans,
  fontWeight: getGelTokens().global.fontWeightBold,
  paddingTop: getGelTokens().global.sizeBaseX6,
}));

export const StyledSubHeaderText = styled.span(() => ({
  color: getGelTokens().global.themeColorInteractive1Active,
  fontSize: `${getGelTokens().global.fontSizeHeadingMedium}px`,
  fontFamily: getGelTokens().global.fontFamilySans,
  fontWeight: getGelTokens().global.fontWeightBold,
  paddingTop: getGelTokens().global.sizeBaseX6,
  paddingLeft: getGelTokens().global.sizeBaseX10,
}));

export const StyledParagraphNumber = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
}));

export const StyledParaStart = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeCaption,
}));

export const StyledParaLink = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextAccent,
  fontSize: getGelTokens().global.fontSizeCaption,
  textDecoration: "underline",
  display: "block",
}));

export const StyledLink = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextAccent,
  fontSize: getGelTokens().global.fontSizeCaption,
  textDecoration: "underline",
}));

export const StyledParagraphBulletFirst = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX16,
  display: "block",
}));

export const StyledParaText = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  display: "block",
}));

export const StyledParaTextParticipant = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX2}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  display: "block",
}));

export const StyledParaSubText = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  display: "block",
}));

export const StyledParagraphBulletSub = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX32,
}));

export const StyledParagraph = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX12,
}));

export const StyledSubParagraph = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX11,
}));

export const StyledBulletParagraph = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  display: "inline-block",
}));

export const StyledParagraphSub = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  paddingTop: `${getGelTokens().global.sizeBaseX24}px`,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  paddingLeft: getGelTokens().global.sizeBaseX17,
  display: "block",
}));

export default Dashboard;
