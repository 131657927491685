import styled from "@emotion/styled";
import { getGelTokens } from "@tal-gel/theming";

const OrgSubmitStatus = (props: any) => {
  return (
    <>
      <StyledHeaderText>
        Your application is submitted. What's Next?
      </StyledHeaderText>
      <StyledContainer>
        <StyledContainer>
          <StyledSubHeaderText>
            1. We will review your organisations application
          </StyledSubHeaderText>
        </StyledContainer>

        <StyledSubContainer>
          <StyledParaText>
            Before we activate your account, we will internally review this
            application. You can
          </StyledParaText>
          <StyledParaSubText>
            expect an outcome within 2 working days
          </StyledParaSubText>
        </StyledSubContainer>
        <StyledContainer>
          <StyledSubHeaderText>
            2. We will notify you of the outcome
          </StyledSubHeaderText>
        </StyledContainer>

        <StyledSubContainer>
          <StyledParaText>
            The outcome of the application will be notified to you at the email
            address you provide.
          </StyledParaText>
          <StyledParaSubText>
            If successful, this will allow you to activate your account
          </StyledParaSubText>
        </StyledSubContainer>

        <StyledContainer>
          <StyledSubHeaderText>3. You can add more users</StyledSubHeaderText>
        </StyledContainer>

        <StyledSubContainer>
          <StyledParaText>
            As a super user you will be required to add other users to your
            organisation. Once your
          </StyledParaText>
          <StyledParaSubText>
            account is active you can also start to lodge claims via this portal
          </StyledParaSubText>
        </StyledSubContainer>
      </StyledContainer>
    </>
  );
};

export const StyledParaSubText = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  display: "block",
  paddingLeft: getGelTokens().global.sizeBaseX15,
}));

const StyledSubContainer = styled.div`
  padding-top: ${getGelTokens().global.sizeBaseX2}px;
`;

export const StyledParaText = styled.span(() => ({
  fontFamily: getGelTokens().global.fontFamilyCondensed,
  color: getGelTokens().global.themeColorTextLight,
  fontSize: getGelTokens().global.fontSizeBodySmall,
  display: "block",
  paddingLeft: getGelTokens().global.sizeBaseX15,
}));

export const StyledSubHeaderText = styled.span(() => ({
  color: getGelTokens().global.themeColorTextLight,
  fontSize: `${getGelTokens().global.fontSizeBodyLarge}px`,
  fontFamily: getGelTokens().global.fontFamilySans,
  paddingTop: getGelTokens().global.sizeBaseX6,
  paddingLeft: getGelTokens().global.sizeBaseX10,
}));

export const StyledHeaderText = styled.span(() => ({
  color: getGelTokens().global.themeColorSuccess,
  fontSize: `${getGelTokens().global.fontSizeHeadingMedium}px`,
  fontFamily: getGelTokens().global.fontFamilySans,
  fontWeight: getGelTokens().global.fontWeightBold,
}));

const StyledContainer = styled.div`
  padding-top: ${getGelTokens().global.sizeBaseX4}px;
`;

export default OrgSubmitStatus;
