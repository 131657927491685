import { ReactNode, createContext, useState } from "react";
import React from "react";

type Props = {
  children?: ReactNode;
};

export type UserContext = {
  factorId: string;
  stateToken: string;
  mobileNumber: string;
  emailAddress: string;
  organisation: string;
  userId: string;
  userType: string;
};

type IUserContext = {
  contextData: UserContext;

  setUserContext: (contextData: UserContext) => void;
};

const initialValue = {
  contextData: {
    factorId: "",
    stateToken: "",
    mobileNumber: "",
    emailAddress: "",
    organisation: "",
    userId: "",
    userType: "",
  },
  setUserContext: () => {},
};

const UserContext = createContext<IUserContext>(initialValue);

const UserContextProvider = ({ children }: Props) => {
  const [contextData, setUserContext] = useState(initialValue.contextData);

  return (
    <UserContext.Provider value={{ contextData, setUserContext }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
