import {
  GelButton,
  GelPageLayout,
  GelPageContent,
  GelPageHeader,
  GelIcon,
  GelBoxLayout,
  GelHeading4,
} from "@tal-gel/components";
import styled from "@emotion/styled";
import { getGelTokens } from "@tal-gel/theming";
import { useParams,useNavigate } from "react-router-dom";


const GenericError = (props: any) => {
    const { urlParam } = useParams();
  const { primaryHeading, primaryAction, secondaryAction, actionButtonName, actionButtonUrl } = props;
  const navigate = useNavigate();
    const clickActionButton = () => {
     

      navigate(actionButtonUrl);
    };
    console.log(window.location.href);
    console.log(window.location.origin);
  return (
    <>
      <GelPageLayout>
        <GelPageHeader></GelPageHeader>
        <GelPageContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "stretch",
            flex: "1 0 0",
            gap: "32px",
            valign: "middle",
            background: getGelTokens().global.themeColorBackgroundLight,
            paddingTop: getGelTokens().global.gridSidenavWidth,
          }}
        >
      
            <StyledDiv>
              <StyledSuccessDiv>
                <StyledTitleDiv>
                  <GelIcon
                    name="AlertCircle"
                    width={getGelTokens().global.sizeBaseUnit * 12}
                    color={getGelTokens().global.themeColorIconDanger}
                    backgroundColor={getGelTokens().global.themeColorIconDanger}
                  />
                  <GelHeading4
                    style={{
                      color: getGelTokens().global.themeColorTextDefault,
                    }}
                  >
                                  { primaryHeading }
                  </GelHeading4>
                </StyledTitleDiv>
                <StyledSuccDescDiv>
                  <p
                    style={{
                      font: "Pluto Sans",
                      display: "block",
                      color: getGelTokens().global.themeColorTextLight,
                      fontSize: getGelTokens().global.fontSizeBodyXLarge,
                      fontWeight: "400",
                      lineHeight: "22.5px",
                      letterSpacing: "-0.25px",
                    }}
                  >
                                  {primaryAction}
                                  <br /><br /><br />
                                  {secondaryAction}
                  </p>
                </StyledSuccDescDiv>
              </StyledSuccessDiv>
              <GelBoxLayout
                space="auto"
                style={{
                  paddingTop: getGelTokens().global.sizeBaseX10,
                  align: "center",
                }}
              >
                          <GelButton secondary large onClick={clickActionButton}>
                              {actionButtonName}
                </GelButton>
              </GelBoxLayout>
            </StyledDiv>
        </GelPageContent>
      </GelPageLayout>
    </>
  );
};

const StyledSuccessDiv = styled.div`
  ${() =>
    `
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    background-color: #FFFFFF;
   
  `}
`;

const StyledDiv = styled.div`
  ${() =>
    `
    valign: "middle",
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    align: center;
  `}
`;

const StyledTitleDiv = styled.div`
  ${() =>
    `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    
  `}
`;

const StyledSuccDescDiv = styled.div`
  ${() =>
    `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    
  `}
`;

export default GenericError;
