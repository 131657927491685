import { GelButton, useGelFormData } from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";

export const ReviewClaim = (props: any) => {
  const { setShowForm, setShowReview } = props;

  const { onMultiStepFormDataSubmit } = useGelFormData();

  const onSubmit = () => {
    setShowForm(false);
    setShowReview(true);
  };

  return (
    <div style={{ paddingTop: getGelTokens().global.sizeBaseX1 }}>
      <GelButton primary medium onClick={onMultiStepFormDataSubmit(onSubmit)}>
        REVIEW CLAIM
      </GelButton>
    </div>
  );
};
