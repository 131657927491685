import React, { useContext, useState, useEffect } from "react";
import {
  GelForm,
  GelFormField,
  GelEmailInput,
  GelColumnLayout,
  GelPasswordInput,
  useGelFormData,
  GelButton,
  GelBoxLayout,
  GelLink,
  GelPageLayout,
  GelPageHeader,
  GelPageContent,
  GelPageFooter,
  GelPageContentContainer,
  GelParagraph,
  GelHeading3,
  GelHeading1,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { useNavigate, useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import styled from "@emotion/styled";
import AccountHeader from "../../components/layout/AccountHeader";
import Footer from "../../components/layout/Footer";
import {
  AuthnTransactionData,
  TRANSACTION_STATUS,
} from "../../common/authentication/auth.utils";
import { UserContext } from "../../common/usercontext/user.context";
import { AccountApi } from "../../common/api/auth.provider";

const Login = () => {
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();

  const [loginStatusMessage, setloginStatusMessage] = useState("");
  const [authError, setauthError] = useState(false);
  useEffect(() => {}, [authError]);

  const { setUserContext } = useContext(UserContext);

  const {
    formData,
    onFormDataChange,
    onFormDataReset,
    resetForm,
    shouldValidateAfterInit,
  } = useGelFormData({
    emailAddress: "",
    password: "",
  });

  const registerOrganisation = () => {
    navigate("/registerOrganisation");
  };

  const resetAuthErrorMessage = (event: any) => {
    // set the value of setAuthErrorMessage to empty string
    setauthError(false);
    onFormDataChange(event);
  };
  const onSubmit = () => {
    oktaAuth.revokeAccessToken().then(() => {
      oktaAuth
        .signIn({
          username: formData.emailAddress,
          password: formData.password,
        })
        .then((res: AuthnTransactionData) => {
          console.log(res.status);
          if (res.status === TRANSACTION_STATUS.MFA_REQUIRED) {
            //setloginStatusMessage("Valid credentials, MFA Required");

            const factor = res.factors?.find((f) => f.factorType === "sms");
            if (!factor) {
              console.log(`User not enrolled in sms factor`);
            }
            const phoneNumber = factor?.profile.phoneNumber
              .replaceAll(" ", "")
              .replaceAll("X", "*");

            navigate("/mfa");
            setUserContext({
              factorId: factor?.id,
              stateToken: res.data?.stateToken!,
              mobileNumber: phoneNumber,
              emailAddress: formData.emailAddress,
              organisation: "",
              userId: "",
              userType: "",
            });

            AccountApi.sendMfaCode(factor?.id, res.data?.stateToken!)
              .then((response: any) => response.json())
              .then((data: any) => {
                console.log("sms sent", data);
              })
              .catch((httpError) => {
                console.log("failed to send MFA sms", httpError);
                setloginStatusMessage("Failed sending sms. Please try again");
              });
          } else if (res.status === TRANSACTION_STATUS.SUCCESS) {
            oktaAuth.signInWithRedirect({
              sessionToken: res.sessionToken,
            });

            console.log(
              "MFA not required for member, authentication succcessful"
            );
            setloginStatusMessage(
              "MFA not required for member, authentication succcessful"
            );
            // Store automation test account password in session storage for redirect to EFT/TFN microsites
            //Storage.setItem(SESSION_STORAGE_KEY.ACCOUNT_TYPE, ACCOUNT_TYPE.AUTOMATION_ACCOUNT);
            // Retreive ID & Access token from Okta

            //oktaAuth.signInWithRedirect({sessionToken: res.data.sessionToken});
          } else {
            setauthError(true);
            setloginStatusMessage(
              "Either your username or password are incorrect"
            );
          }
        })
        .catch((httpError) => {
          if (httpError.message === "Authentication failed") {
            setauthError(true);
            setloginStatusMessage(
              "Either your username or password are incorrect"
            );
          } else {
            console.log("failed to login", httpError);
            setloginStatusMessage(httpError.message);
          }
        });
    });
  };

  return (
    <GelPageLayout>
      <GelPageHeader>
        <AccountHeader />
      </GelPageHeader>
      <GelPageContentContainer>
        <GelPageContent>
          <SignUpDiv>
            <GelForm
              labelAtTop
              disableOnSubmit
              shouldValidateAfterInit={shouldValidateAfterInit}
              onSubmit={onSubmit}
            >
              <GelColumnLayout
                distribution="1/2 1/2"
                style={{
                  width: "100%",
                }}
              >
                <Child>
                  <SignUpInternal>
                    <GelHeading1>Log into Third Party Portal</GelHeading1>
                    <br></br>
                    <GelParagraph
                      style={{
                        fontSize: getGelTokens().global.fontSizeBodyXLarge,
                      }}
                    >
                      Dont have an account?{" "}
                      <GelLink onClick={registerOrganisation}>
                        Sign up now
                      </GelLink>
                    </GelParagraph>
                    <br></br>
                    <GelHeading3>Enter Email Address</GelHeading3>
                    <br />
                    <GelFormField>
                      <GelEmailInput
                        name="emailAddress"
                        data-testid="emailAddress"
                        maxLength={120}
                        onChange={resetAuthErrorMessage}
                        value={formData.emailAddress}
                        required
                        errorMsg={{
                          required: "Please enter an email address",
                          format: "Please enter a valid email address",
                        }}
                        style={{ width: "85%" }}
                      ></GelEmailInput>
                    </GelFormField>
                    <br />
                    <GelHeading3>Enter Password</GelHeading3>
                    <br />
                    <GelFormField>
                      <GelPasswordInput
                        name="password"
                        data-testid="password"
                        value={formData.password}
                        onChange={resetAuthErrorMessage}
                        required
                        errorMsg={{
                          required: "Password is required",
                        }}
                        style={{ width: "85%" }}
                      />
                    </GelFormField>
                    <GelParagraph
                      style={{
                        color: getGelTokens().global.themeColorBackgroundDanger,
                        fontSize: getGelTokens().global.sizeBaseX3,
                      }}
                    >
                      {authError
                        ? "Either your username or password are incorrect"
                        : "\u00A0"}
                    </GelParagraph>
                    <br></br>
                    <GelBoxLayout space="auto" distribution="start">
                      <GelFormField>
                        <GelButton name="" primary medium submit>
                          CONTINUE
                        </GelButton>
                      </GelFormField>
                    </GelBoxLayout>
                    <br></br>
                    <GelParagraph
                      style={{
                        fontSize: getGelTokens().global.fontSizeBodyXLarge,
                      }}
                    >
                      <GelLink>Forgot password?</GelLink>
                    </GelParagraph>
                  </SignUpInternal>
                </Child>
                <Child>
                  <SignUpInternalRight>
                    <GelHeading1
                      style={{
                        color: getGelTokens().global.themeColorTextInverse,
                        fontSize: getGelTokens().global.fontSizeDisplayLarge,
                      }}
                    >
                      Claims
                    </GelHeading1>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <GelHeading1
                      style={{
                        color: getGelTokens().global.themeColorTextInverse,
                        fontSize: getGelTokens().global.fontSizeDisplayLarge,
                      }}
                    >
                      Lodgement
                    </GelHeading1>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <GelHeading1
                      style={{
                        color: getGelTokens().global.themeColorTextInverse,
                        fontSize: getGelTokens().global.fontSizeDisplayLarge,
                      }}
                    >
                      Portal
                    </GelHeading1>
                  </SignUpInternalRight>
                </Child>
              </GelColumnLayout>
            </GelForm>
          </SignUpDiv>
        </GelPageContent>
      </GelPageContentContainer>
      <GelPageFooter>
        <Footer />
      </GelPageFooter>
    </GelPageLayout>
  );
};

const Child = styled.div(() => ({
  height: 530,
  width: "100%",
}));

const SignUpDiv = styled.div(() => ({
  paddingTop: getGelTokens().global.sizeBaseX20,
  paddingBottom: getGelTokens().global.sizeBaseX20,
  minHeight: "90%",
  alignItems: "center",
  marginRight: "0px",
  paddingRight: "0px",
}));

const SignUpInternal = styled.div(() => ({
  minHeight: "100%",
  alignItems: "center",
  paddingTop: getGelTokens().global.sizeBaseX10,
  paddingBottom: getGelTokens().global.sizeBaseX10,
  paddingLeft:
    getGelTokens().global.sizeBaseX20 +
    getGelTokens().global.sizeBaseX20 +
    getGelTokens().global.sizeBaseX20,
  paddingRight:
    getGelTokens().global.sizeBaseX20 + getGelTokens().global.sizeBaseX20,
}));

const SignUpInternalRight = styled.div(() => ({
  paddingTop: getGelTokens().global.sizeBaseX20,
  paddingBottom: getGelTokens().global.sizeBaseX5,
  paddingLeft: getGelTokens().global.sizeBaseX5,
  minHeight: "100%",
  alignItems: "center",
  backgroundColor: getGelTokens().global.themeColorBackgroundInverse,
}));

const SignContainer = styled.div(() => ({
  padding: getGelTokens().global.sizeBaseX10,
  height: "100%",
}));

// const Child = styled.div`
//   ${() =>
//     `
//     width: "100%";
//     height: "100%";
//   `}
// `;

export default Login;
