import {
  GelBoxLayout,
  GelButton,
  GelForm,
  GelFormField,
  GelIcon,
  GelLink,
  GelRadio,
  GelRadioButtonGroup,
  useGelFormData,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import axios from "axios";
import { StyledFormContainer } from "./Form";
import styled from "@emotion/styled";
import Uploader from "../../components/layout/Uploader";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fund } from "../../models/Fund";

interface AuthorisationStepProps {
  lodgement: any;
  funds: Fund[] | null;
  setLodgement: (lodgement: any) => any;
  saveLodgement: (lodgement: any) => any;
  setIsUploadingForm?: (isUploading: boolean) => void;
  setScreen: (screen: string) => void;
}

export const AuthorisationStep = (props: AuthorisationStepProps) => {
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { lodgement, setLodgement, saveLodgement, setIsUploadingForm } = props;
  const { funds, setScreen } = props;
  const {
    formData,
    onFormDataChange,
    onPrevStep,
    onNextStep,
    setFormData,
    validateAfterInit,
    shouldValidateAfterInit,
  } = useGelFormData(
    {
      thirdParty: "",
    },
    "authorisation"
  );
  const navigate = useNavigate();

  useEffect(() => {
    setScreen("Authorisation");
  }, []);
  const handleIsUploadingChange = (isUploading: boolean) => {
    setIsUploading(isUploading);
    if (setIsUploadingForm) setIsUploadingForm(isUploading);
  };
  const selectedFund = funds
    ? funds.find((obj) => {
        return obj.fundId === lodgement.memberDetails.fundId;
      })
    : null;
  useEffect(() => {
    if (lodgement?.authorisation?.authorisationId) {
      setFormData((prevData: any) => ({
        ...lodgement.authorisation,
      }));
      validateAfterInit();
    }
  }, [lodgement]);

  const updateLodgement = async () => {
    if (isCbusOrMediaSuper) formData.thirdParty = "Yes";
    await saveLodgement({
      ...lodgement,
      authorisation: formData,
    });
    onNextStep();
  };

  const showUpload =
    formData.thirdParty === "Yes" ||
    lodgement.memberDetails.fundId === "2" ||
    lodgement.memberDetails.fundId === "3" ||
    lodgement.memberDetails.fundId === "6";

  const isCbusOrMediaSuper =
    formData.thirdParty === "Yes" ||
    lodgement.memberDetails.fundId === "2" ||
    lodgement.memberDetails.fundId === "3" ||
    lodgement.memberDetails.fundId === "6";

  const showThirdParty =
    lodgement.memberDetails.fundId === "1" ||
    lodgement.memberDetails.fundId === "5";

  const downloadTpdForm = () => {
    var url: string = "";
    var fundAlias = selectedFund?.fundAlias?.toLowerCase();
    // Replace space with _ in fundAlias
    var fundTag = fundAlias?.replace(/\s/g, "_");
    var cmsQuery =
      `{  acpContentCollection(    where: {contentfulMetadata: {tags: {id_contains_all: "` +
      fundTag +
      `"}}}  ) {    items {      tpa {        title        contentType        fileName        url      }    }  }}`;

    const loadDocument = async () => {
      setLoading(true);
      try {
        const cmsResponse = await axios.post(
          `https://graphql.contentful.com/content/v1/spaces/` +
            process.env.REACT_APP_CONTENTFUL_SPACE_ID +
            `/environments/` +
            process.env.REACT_APP_CONTENTFUL_ENVIRONMENT +
            `?access_token=` +
            process.env.REACT_APP_CONTENTFUL_API_TOKEN,
          {
            query: cmsQuery,
          }
        );
        console.log(cmsResponse);
        if (
          cmsResponse.data.data.acpContentCollection.items &&
          cmsResponse.data.data.acpContentCollection.items.length > 0
        )
          url = cmsResponse.data.data.acpContentCollection.items[0].tpa.url;
        else
          url =
            "https://www.tal.com.au/-/media/tal/files/investments-and-superannuation/service-forms/thirdpartyauthorityform.pdf";
      } catch {
        navigate("/error");
      } finally {
        setLoading(false);
      }
      // window.location.href = url;
      window.open(url, "_blank");
    };
    loadDocument();
  };

  return (
    <GelForm
      labelAtTop
      disableOnSubmit
      onSubmit={updateLodgement}
      shouldValidateAfterInit={shouldValidateAfterInit}
    >
      <StyledFormContainer>
        {showThirdParty && (
          <GelFormField label="Is this claim represented by a third-party?">
            <GelRadioButtonGroup
              name="thirdParty"
              value={formData.thirdParty}
              onChange={onFormDataChange}
              required={!formData.thirdParty}
              errorMsg={{
                required: "Please select one option",
              }}
            >
              <GelRadio style={{ width: "48%" }} value={"Yes"}>
                Yes
              </GelRadio>
              <GelRadio style={{ width: "48%" }} value={"No"}>
                No
              </GelRadio>
            </GelRadioButtonGroup>
          </GelFormField>
        )}
        {showUpload && (
          <>
            <GelIcon
              name="Download"
              width={getGelTokens().global.sizeBaseX6}
              height={getGelTokens().global.sizeBaseX6}
              color={getGelTokens().brand.brandColorPrimary1}
              onClick={downloadTpdForm}
              inline
            />{" "}
            <GelLink onClick={downloadTpdForm}>
              Download the TAL Third Party Authority Form
            </GelLink>
            <StyledAlertDiv>
              <GelIcon name="AlertCircle" color="#f7931e" />
              <div style={{ marginLeft: "15px" }}>
                <strong>Please provide a TAL Third Party Authority Form</strong>
                <p
                  style={{
                    font: "Pluto Sans",
                    fontSize: "15px",
                    fontWeight: "400",
                    lineHeight: "22.5px",
                    letterSpacing: "-0.25px",
                    margin: getGelTokens().global.sizeNone,
                    color: getGelTokens().global.themeColorBlack,
                  }}
                >
                  Please note: If you do not provide a TAL Third Party Authority
                  Form, correspondence will solely be directed to the claimant.
                  We will be unable to discuss the claim with you until this is
                  provided.
                </p>
              </div>
            </StyledAlertDiv>
          </>
        )}
        {showUpload && (
          <Uploader
            lodgement={lodgement}
            setLodgement={setLodgement}
            type={"authorisation"}
            setIsUploading={handleIsUploadingChange}
          ></Uploader>
        )}
        <GelBoxLayout space="auto" distribution="start">
          <GelFormField>
            <GelButton
              name=""
              secondary
              medium
              onClick={onPrevStep}
              disabled={isUploading}
            >
              BACK
            </GelButton>
          </GelFormField>
          <GelFormField>
            <GelButton name="" primary medium submit disabled={isUploading}>
              SAVE & CONTINUE
            </GelButton>
          </GelFormField>
        </GelBoxLayout>
      </StyledFormContainer>
    </GelForm>
  );
};

const StyledAlertDiv = styled.div`
  ${() =>
    `
    background-color: #fbc98f;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 2px;
    margin: 16px 0;
    display: flex;
  `}
`;
