import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserSessionContext } from "../../common/usercontext/usersession.context";

const SetupHttpClient: React.FC = (props: { children?: any }) => {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  const { userSessionContextData, setUserSessionContext } =
    useContext(UserSessionContext);

  useEffect(() => {
      const requestInterceptor = axios.interceptors.request.use(
          (request) => {
            if (!request.url?.startsWith("https://graphql.contentful.com")){ 
              const accessToken = oktaAuth.getAccessToken();

          request.headers.Authorization = accessToken
              ? `Bearer ${accessToken}`
              : "";

              if (
            !!userSessionContextData.encryptedUserSession &&
            request.headers.encryptedUserSession !=
              userSessionContextData.encryptedUserSession
          ) {
            request.headers.encryptedUserSession =
              userSessionContextData.encryptedUserSession;
          }
        }
        return request;
      },

        (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        if (
          !!response.headers.encryptedusersession &&
          response.headers.encryptedusersession !==
            userSessionContextData.encryptedUserSession
        ) {
          setUserSessionContext({
            encryptedUserSession: response.headers.encryptedusersession,
          });
        }
        return response;
      },
        async (error) => {
            console.log(error.response);
            if (error.response.status === 401 && error.response?.data?.Detail === "Invalid User Session.") {
                setUserSessionContext({
                    encryptedUserSession: "",
                });
            oktaAuth.signOut();

            } 
        if (error.response.status === 401 && !oktaAuth.isAuthenticated) {
          navigate("/login");
        }

        return Promise.reject(error);
      }
    );

    return () => {
      // Remove the interceptors when the component is unmounted
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [userSessionContextData]); // Add userSessionContextData as a dependency

  return props.children;
};

export default SetupHttpClient;
