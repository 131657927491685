import {
  GelPageLayout,
  GelPageContent,
  GelPageHeader,
  GelHeading3,
  GelTextInput,
  GelForm,
  GelFormField,
  useGelFormData,
  GelColumnLayout,
  GelRow,
  GelCol,
  GelSpinner,
  GelEmailInput,
  GelPhoneNumberInput,
  GelSelect,
  GelBoxLayout,
  GelButton,
  GelModal,
} from "@tal-gel/components";
import { useNavigate, useParams } from "react-router-dom";
import { getGelTokens } from "@tal-gel/theming";
import styled from "@emotion/styled";
import { useState, useEffect, useContext } from "react";
import Header from "../../components/layout/Header";
import axios from "axios";
import { Role } from "../../models/Role";
import { Branch } from "../../models/Branch";
import { useOktaAuth } from "@okta/okta-react";
import { CustomUserClaims, UserClaims, decodeToken } from "@okta/okta-auth-js";
import { UserForm } from "../../models/UserForm";
import { UserContext } from "../../common/usercontext/user.context";
import { User } from "../../models/User";

const CreateUser = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [showUserSubmitModal, setShowUserSubmitModal] = useState(false);
  const { oktaAuth, authState } = useOktaAuth();
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [roleId, setRoleId] = useState<string>("");
  const [roles, setRoles] = useState<Role[] | null>(null);
  const [branches, setBranches] = useState<Branch[] | null>(null);

  const [organisationId, setOrganisationId] = useState<string>("");

  const { contextData } = useContext(UserContext);

  const { formData, onFormDataChange, shouldValidateAfterInit, setFormData } =
    useGelFormData({
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      emailAddress: user?.email || "",
      mobileNumber: user?.mobilePhone || "",
      roleId: user?.roleId || "",
      branchId: user?.branchId || "",
    });

  const roleOptions = roles
    ? roles.map((role) => {
        return { label: role.roleName, value: role.roleId };
      })
    : [];

  const branchOptions = branches
    ? branches.map((branch) => {
        return { label: branch.branchName, value: branch.branchId };
      })
    : [];

  const getUserQuery = `query User($userId: String!) {
      userById(userId: $userId) {
        userId,
        firstName,
        lastName,
        email,
        mobilePhone,
        branchId,
        roleId
    }
  }`;

  const loadUser = async () => {
    setLoading(true);
    const variables = {
      userId: userId,
    };
    try {
      const existingUserResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
        {
          query: getUserQuery,
          variables,
        }
      );
      setUser(existingUserResponse.data.data.userById);
      setFormData({
        firstName: existingUserResponse.data.data.userById?.firstName,
        lastName: existingUserResponse.data.data.userById?.lastName,
        emailAddress: existingUserResponse.data.data.userById?.email,
        mobileNumber:
          existingUserResponse.data.data.userById?.mobilePhone.replace(
            "+61",
            0
          ) || "04444444444",
        roleId: existingUserResponse.data.data.userById?.roleId,
        branchId: existingUserResponse.data.data.userById?.branchId,
      });
    } catch {
      navigate("/error");
    } finally {
      setLoading(false);
    }
  };

  const onModalClose = () => {
    setShowUserSubmitModal(false);
    backtoUserMgrDashboard();
  };

  const createUser = () => {
    setShowUserSubmitModal(false);
    formData.firstName = "";
    formData.lastName = "";
    formData.emailAddress = "";
    formData.mobileNumber = "";
    formData.branchId = "";
    formData.roleId = "";
  };

  const backtoUserMgrDashboard = () => {
    navigate("/manageUsers");
  };

  const loadRoles = async () => {
    setLoading(true);
    try {
      const rolesResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
        {
          query: `query {
                          roles {
                            roleId,
                            roleName,
                            roleDescription
                            }
                        }`,
        }
      );
      var fetchedRoles: Role[] = rolesResponse.data.data.roles;
      setRoles(fetchedRoles);
    } catch {
      navigate("/error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadRoles();
    loadBranchesByOrganisationId();

    if (userId) {
      setIsEditMode(true);
      loadUser();
    }
  }, []);

  const loadBranchesByOrganisationId = async () => {
    setLoading(true);
    try {
      const organisation = contextData.organisation;
      console.log(contextData);

      if (contextData.userType !== "DB69DAA7-224D-4D81-8ECC-F9DFD5A0A4B7")
        navigate("/notAllowed");
      let fetchedBranches: Branch[] | null = null;

      if (organisation) {
        const variables = {
          organisationId: organisation,
        };
        const branchesQuery = `
              query {
                branchesByOrganisationId(organisationId: "${organisation}") {
                  branchId,
                  branchName
                }
        }`;
        const branchesResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
          {
            query: branchesQuery,
            variables,
          }
        );
        fetchedBranches = branchesResponse.data.data.branchesByOrganisationId;
      } else {
        const variables = {
          userId: contextData.userId,
        };
        const branchesQuery = `
              query {
                branchesByUserId(userId: "${contextData.userId}") {
                  branchId,
                  branchName
                }
        }`;
        const branchesResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
          {
            query: branchesQuery,
            variables,
          }
        );
        fetchedBranches = branchesResponse.data.data.branchesByUserId;
      }

      setBranches(fetchedBranches);
    } catch {
      navigate("/error");
    } finally {
      setLoading(false);
    }
  };

  function getRoleNameById(roleId: any) {
    const role = roleOptions.find((option) => option.value === roleId);
    return role ? role.label : null;
  }

  const submitUser = async () => {
    if (!formData.roleId || !formData.branchId) {
      return;
    }
    setLoading(true);
    const userForm: UserForm = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.emailAddress,
      mobilePhone: formData.mobileNumber,
      organization: contextData.organisation,
      branchId: formData.branchId,
      userType: getRoleNameById(formData.roleId) || "",
      roleId: formData.roleId,
    };

    const editUser = {
      userId: user?.userId,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.emailAddress,
      mobilePhone: formData.mobileNumber,
      branchId: formData.branchId,
      roleId: formData.roleId,
    };
    const createUserQuery = `
    mutation RegisterUser($userForm: UserFormInput!) {
      registerUser(userForm: $userForm) {
        userId
      }
  }`;

    const editUserQuery = `
    mutation UpdateUser($saveUserRequest: SaveUserRequestInput!) {
      updateUser(saveUserRequest: $saveUserRequest) {
      userId
  }
}`;
    const variables = {
      userForm: userForm,
    };

    const editvariables = {
      saveUserRequest: {
        requestOperation: "Update",
        user: editUser,
      },
    };
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/graphql/`, {
        query: isEditMode ? editUserQuery : createUserQuery,
        variables: isEditMode ? editvariables : variables,
      });
      if (isEditMode) navigate("/manageUsers");
      else {
        setShowUserSubmitModal(true);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.errors &&
        Array.isArray(error.response.data.errors) &&
        error.response.data.errors.length > 0
      ) {
        var errorMsg = error.response.data.errors[0].message;
        if (errorMsg.indexOf("User Already Exists") !== -1)
          navigate("/duplicateUser");
        if (errorMsg.indexOf("is not allowed with this email domain") !== -1)
          navigate("/invalidDomainForUserError");
        else navigate("/registerUserError");
      } else navigate("/registerUserError");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <GelPageLayout>
        <GelPageHeader>
          <Header />
        </GelPageHeader>
        <GelPageContent>
          <StyledUserContainer>
            <GelForm
              labelAtTop
              disableOnSubmit
              shouldValidateAfterInit={shouldValidateAfterInit}
            >
              {loading && <GelSpinner large overlay />}
              <GelHeading3>PERSONAL DETAILS</GelHeading3>
              <br />
              <GelColumnLayout
                style={{
                  backgroundColor:
                    getGelTokens().global.themeColorBackgroundDefault,
                  width: "60%",
                  align: "center",
                }}
              >
                <GelRow>
                  <GelCol>
                    <GelFormField label="First Name">
                      <GelTextInput
                        name="firstName"
                        data-testid="firstName"
                        maxLength={120}
                        onChange={onFormDataChange}
                        value={formData.firstName}
                        required
                        regex={"^[a-zA-Z-'\\s]*$"}
                        errorMsg={{
                          required: "Please enter First Name",
                          regex:
                            "Please enter letters, spaces, dashes (-) and apostrophes (') only",
                        }}
                      ></GelTextInput>
                    </GelFormField>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelFormField label="Last Name">
                      <GelTextInput
                        name="lastName"
                        data-testid="lastName"
                        maxLength={120}
                        onChange={onFormDataChange}
                        value={formData.lastName}
                        required
                        regex={"^[a-zA-Z-'\\s]*$"}
                        errorMsg={{
                          required: "Please enter Last Name",
                          regex:
                            "Please enter letters, spaces, dashes (-) and apostrophes (') only",
                        }}
                      ></GelTextInput>
                    </GelFormField>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelHeading3>CONTACT DETAILS</GelHeading3>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelFormField
                      label="Email Address"
                      tooltip="Email address must match the organisations email domain"
                    >
                      <GelEmailInput
                        name="emailAddress"
                        data-testid="emailAddress"
                        maxLength={120}
                        onChange={onFormDataChange}
                        value={formData.emailAddress}
                        required
                        disabled={isEditMode}
                        errorMsg={{
                          required: "Please enter Email Address",
                          format: "Please enter valid Email Address",
                        }}
                      ></GelEmailInput>
                    </GelFormField>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelFormField label="Mobile Number">
                      <GelPhoneNumberInput
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={onFormDataChange}
                        disabled={isEditMode}
                        required
                        errorMsg={{
                          required: "Please enter Mobile Number",
                        }}
                      />
                    </GelFormField>
                  </GelCol>
                </GelRow>
              </GelColumnLayout>
              <br />
              <GelColumnLayout
                style={{
                  backgroundColor:
                    getGelTokens().global.themeColorBackgroundDefault,
                  width: "60%",
                  align: "center",
                }}
              >
                <GelRow>
                  <GelCol>
                    <GelHeading3>ROLES AND GROUPS</GelHeading3>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelFormField
                      label="Role"
                      tooltip="User and access control allows the user to both lodge claims and manage other users access within the organisation. The user role allows the new user to create claims only."
                    >
                      <GelSelect
                        name="roleId"
                        value={formData.roleId}
                        placeholder="Please select"
                        onChange={onFormDataChange}
                        options={roleOptions}
                        required={!formData.roleId}
                      />
                    </GelFormField>
                  </GelCol>
                </GelRow>
                <GelRow>
                  <GelCol>
                    <GelFormField
                      label="Group"
                      tooltip="To add other groups go to Organisation Management"
                    >
                      <GelSelect
                        name="branchId"
                        value={formData.branchId}
                        placeholder="Please select"
                        onChange={onFormDataChange}
                        //options={[{ label: "NSW Police Blue Ribbon", value: "1" }]}
                        options={branchOptions}
                        required={!formData.branchId}
                      />
                    </GelFormField>
                  </GelCol>
                </GelRow>

                <GelRow>
                  <GelCol>
                    <GelBoxLayout
                      gap="small"
                      space="auto"
                      style={{ justifyContent: "left" }}
                    >
                      <GelFormField>
                        <GelButton
                          name=""
                          secondary
                          large
                          onClick={backtoUserMgrDashboard}
                        >
                          Back
                        </GelButton>
                      </GelFormField>
                      <GelFormField>
                        <GelButton
                          onClick={submitUser}
                          name=""
                          primary
                          large
                          submit
                        >
                          Submit
                        </GelButton>
                      </GelFormField>
                    </GelBoxLayout>
                  </GelCol>
                </GelRow>
              </GelColumnLayout>
              <br />
            </GelForm>
            {showUserSubmitModal && (
              <GelModal
                width={getGelTokens().global.sizeBaseUnit * 220}
                open={showUserSubmitModal}
                onClose={onModalClose}
                closeOnBackdropClick={false}
                // action={

                // }
              >
                <div>
                  <StyledHeaderText>
                    An account activation email has been sent to the<br></br>{" "}
                    new user
                  </StyledHeaderText>
                  <br />
                  <br></br>
                  <GelButton
                    primary
                    large
                    onClick={createUser}
                    width={getGelTokens().global.sizeBaseUnit * 200}
                  >
                    Create another new user
                  </GelButton>
                  <br />
                  <GelButton
                    secondary
                    large
                    width={getGelTokens().global.sizeBaseUnit * 200}
                    onClick={backtoUserMgrDashboard}
                  >
                    Back to Dashboard
                  </GelButton>
                </div>
              </GelModal>
            )}
          </StyledUserContainer>
        </GelPageContent>
      </GelPageLayout>
    </>
  );
};

const StyledUserContainer = styled.div`
  ${() =>
    `
    float: left;
    margin-left:  202px;
    margin-top:  40px;
  `}
`;

export const StyledHeaderText = styled.span(() => ({
  //color: getGelTokens().global.themeColorSuccess,
  fontSize: `${getGelTokens().global.fontSizeHeadingMedium}px`,
  fontFamily: getGelTokens().global.fontFamilySans,
  fontWeight: getGelTokens().global.fontWeightBold,
}));

export default CreateUser;
