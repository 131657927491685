import {
  GelHeading6,
  GelLabel,
  GelParagraph,
  GelButton,
  GelIcon,
  GelModal,
  GelProgressStatus,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { useState } from "react";
import Uploader from "../../components/layout/Uploader";
import FileTypes from "./FileTypes";

interface OtherDocumentsProps {
  lodgement: any;
  setLodgement: (lodgement: any) => any;
  setIsUploading?: (isUploading: boolean) => void;
}

const OtherDocuments = (props: OtherDocumentsProps) => {
  const { lodgement, setLodgement, setIsUploading } = props;
  const [openModal, setOpenModal] = useState(false);
  return (
    <div
      style={{
        paddingTop: getGelTokens().global.sizeBaseX4,
        paddingRight: getGelTokens().global.sizeBaseX4,
      }}
    >
      <GelHeading6>Other documents</GelHeading6>
      <GelParagraph>
        These documents are required for claim assessment. If you have them,
        please upload them now. Otherwise, you can provide them
        <br></br>later. We may need to ask for some more documents after we
        begin to assess your claim.
      </GelParagraph>
      <div style={{ display: "flex", paddingBottom: "20px" }}>
        <GelParagraph style={{ paddingRight: "10px" }}>
          Maximum file size: 150 MB per file.
        </GelParagraph>
        <GelButton tertiary small onClick={() => setOpenModal(true)}>
          View all supported file types
        </GelButton>
      </div>
      <GelModal
        width="sm"
        height={getGelTokens().global.sizeBaseUnit * 80}
        open={openModal}
        onClose={() => setOpenModal(false)}
        sticky
        title="Supported file types"
      >
        <FileTypes></FileTypes>
      </GelModal>
      <div
        style={{
          paddingTop: getGelTokens().global.sizeBaseX4,
          border: "1px solid" + getGelTokens().global.themeColorBorderDefault,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            paddingLeft: getGelTokens().global.sizeBaseX4,
            paddingRight: getGelTokens().global.sizeBaseX4,
            paddingBottom: getGelTokens().global.sizeBaseX4,
            borderBottom:
              "1px solid" + getGelTokens().global.themeColorBorderDefault,
          }}
        >
          <GelProgressStatus stepNum={3} active></GelProgressStatus>
          <GelLabel>Other documents (optional)</GelLabel>
        </div>

        <div
          style={{
            display: "flex",
            paddingTop: getGelTokens().global.sizeBaseX4,
          }}
        >
          <GelIcon
            color={getGelTokens().global.themeColorIconDefault}
            name="Bullet"
            width={getGelTokens().global.sizeBaseX4}
            height={getGelTokens().global.sizeBaseX4}
            style={{
              paddingTop: "2px",
              paddingLeft: getGelTokens().global.sizeBaseX4,
              paddingRight: getGelTokens().global.sizeBaseX4,
            }}
          />
          <GelParagraph>Tax File Number form</GelParagraph>
        </div>
        <div style={{ display: "flex" }}>
          <GelIcon
            color={getGelTokens().global.themeColorIconDefault}
            name="Bullet"
            width={getGelTokens().global.sizeBaseX4}
            height={getGelTokens().global.sizeBaseX4}
            style={{
              paddingTop: "2px",
              paddingLeft: getGelTokens().global.sizeBaseX4,
              paddingRight: getGelTokens().global.sizeBaseX4,
            }}
          />
          <GelParagraph>Electronic Funds Transfer form</GelParagraph>
        </div>
        <div style={{ display: "flex" }}>
          <GelIcon
            color={getGelTokens().global.themeColorIconDefault}
            name="Bullet"
            width={getGelTokens().global.sizeBaseX4}
            height={getGelTokens().global.sizeBaseX4}
            style={{
              paddingTop: "2px",
              paddingLeft: getGelTokens().global.sizeBaseX4,
              paddingRight: getGelTokens().global.sizeBaseX4,
            }}
          />
          <GelParagraph>Pay history</GelParagraph>
        </div>
        <div style={{ display: "flex" }}>
          <GelIcon
            color={getGelTokens().global.themeColorIconDefault}
            name="Bullet"
            width={getGelTokens().global.sizeBaseX4}
            height={getGelTokens().global.sizeBaseX4}
            style={{
              paddingTop: "2px",
              paddingLeft: getGelTokens().global.sizeBaseX4,
              paddingRight: getGelTokens().global.sizeBaseX4,
            }}
          />
          <GelParagraph>Certified Proof of Identity</GelParagraph>
        </div>
        <div style={{ display: "flex" }}>
          <GelIcon
            color={getGelTokens().global.themeColorIconDefault}
            name="Bullet"
            width={getGelTokens().global.sizeBaseX4}
            height={getGelTokens().global.sizeBaseX4}
            style={{
              paddingTop: "2px",
              paddingLeft: getGelTokens().global.sizeBaseX4,
              paddingRight: getGelTokens().global.sizeBaseX4,
            }}
          />
          <GelParagraph>
            Third-Party Authority Form (if not provided already)
          </GelParagraph>
        </div>
        <div style={{ display: "flex" }}>
          <GelIcon
            color={getGelTokens().global.themeColorIconDefault}
            name="Bullet"
            width={getGelTokens().global.sizeBaseX4}
            height={getGelTokens().global.sizeBaseX4}
            style={{
              paddingTop: "2px",
              paddingLeft: getGelTokens().global.sizeBaseX4,
              paddingRight: getGelTokens().global.sizeBaseX4,
            }}
          />
          <GelParagraph>Other relevant supporting documents</GelParagraph>
        </div>
        <div
          style={{
            paddingTop: getGelTokens().global.sizeBaseX4,
            paddingLeft: getGelTokens().global.sizeBaseX4,
            paddingRight: getGelTokens().global.sizeBaseX4,
          }}
        >
          <Uploader
            lodgement={lodgement}
            setLodgement={setLodgement}
            type={"other"}
            setIsUploading={setIsUploading}
          />
        </div>
      </div>
    </div>
  );
};

export default OtherDocuments;
