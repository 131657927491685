import React, { useContext, useState, useEffect } from "react";
import {
  GelForm,
  GelFormField,
  GelTextInput,
  GelColumnLayout,
  GelPasswordInput,
  useGelFormData,
  GelButton,
  GelBoxLayout,
  GelLink,
  GelPageLayout,
  GelPageHeader,
  GelPageContent,
  GelPageFooter,
  GelPageContentContainer,
  GelParagraph,
  GelHeading3,
  GelHeading1,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { useNavigate, useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import styled from "@emotion/styled";
import AccountHeader from "../../components/layout/AccountHeader";
import Footer from "../../components/layout/Footer";
import { UserContext } from "../../common/usercontext/user.context";
import { AccountApi } from "../../common/api/auth.provider";

const TwoFactor = () => {
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  const [resendDisabled, setResendDisabled] = useState(false);
  const [mfaStatusMessage, setMfaStatusMessage] = useState("");
  const [resend, setResend] = useState(false);
  const { contextData } = useContext(UserContext);
  const {
    formData,
    onFormDataChange,
    onFormDataReset,
    resetForm,
    shouldValidateAfterInit,
  } = useGelFormData({
    verificationCode: "",
  });

  const onSubmit = () => {
    if (contextData?.factorId && contextData?.stateToken) {
      AccountApi.verifyMfaCode(
        contextData.factorId,
        contextData.stateToken,
        formData.verificationCode
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("okta response ->", data);
          if (data?.status == "SUCCESS")
            oktaAuth.signInWithRedirect({ sessionToken: data.sessionToken });
          else setMfaStatusMessage(data?.errorSummary);
        })
        .catch((httpError) => {
          console.log("MFA verification failed", httpError);
        });
    }
  };

  const onResend = () => {
    if (resendDisabled) return;

    setResendDisabled(true);
    setTimeout(() => {
      setResendDisabled(false);
    }, 10000);
    if (contextData?.factorId && contextData?.stateToken) {
      AccountApi.sendMfaCode(contextData?.factorId, contextData?.stateToken)
        .then((response) => response.json())
        .then((data) => {
          console.log("resend sms", data);

          if (data?.errorCode) setMfaStatusMessage(data.errorSummary);
          else {
            setMfaStatusMessage("");
            setResend(true);
          }
        })
        .catch((httpError) => {
          console.log("failed to send MFA sms", httpError);
          setMfaStatusMessage("Failed sending sms. Please try again");
        });
    }
  };

  const onResponse = (response: any) => {
    console.log("response", response);
  };

  const onError = (error: any) => {
    console.error("error", error);
  };

  // if (!contextData?.stateToken || loading) return <Loading />;

  return (
    <GelPageLayout>
      <GelPageHeader>
        <AccountHeader />
      </GelPageHeader>
      <GelPageContentContainer>
        <GelPageContent>
          <SignUpDiv>
            <GelForm
              labelAtTop
              disableOnSubmit
              shouldValidateAfterInit={shouldValidateAfterInit}
              onSubmit={onSubmit}
              reset={resetForm}
              parseResponseBody={false} // Remove this if you use `fetch` in `onSubmit`
              onResponse={onResponse}
              onError={onError}
            >
              <GelColumnLayout
                distribution="1/2 1/2"
                style={{
                  width: "100%",
                }}
              >
                <Child>
                  <SignUpInternal>
                    {resend ? (
                      <GelHeading1>We have resent</GelHeading1>
                    ) : (
                      <GelHeading1>Enter the code</GelHeading1>
                    )}
                    <br></br>
                    {resend ? (
                      <GelHeading1>the code to</GelHeading1>
                    ) : (
                      <GelHeading1>we sent to</GelHeading1>
                    )}
                    <br></br>
                    <GelHeading1>{contextData.mobileNumber}</GelHeading1>
                    <br></br>
                    <GelParagraph
                      style={{
                        fontSize: getGelTokens().global.fontSizeBodyXLarge,
                      }}
                    >
                      Didn't receive a code?{" "}
                      <GelLink
                        src="/login"
                        onClick={onResend}
                        disabled={resendDisabled}
                      >
                        Resend now
                      </GelLink>
                    </GelParagraph>
                    <br></br>
                    <GelHeading3>Verification code</GelHeading3>
                    <br />
                    <GelFormField>
                      <GelTextInput
                        name="verificationCode"
                        maxLength={120}
                        onChange={onFormDataChange}
                        value={formData.verificationCode}
                        required
                        style={{ width: "85%" }}
                      ></GelTextInput>
                    </GelFormField>
                    <br />
                    <GelBoxLayout space="auto" distribution="start">
                      <GelFormField>
                        <GelButton
                          name="" // Makes it a managed component
                          primary
                          large
                          submit
                        >
                          VERIFY
                        </GelButton>
                      </GelFormField>
                    </GelBoxLayout>
                    <br></br>
                  </SignUpInternal>
                </Child>
                <Child>
                  <SignUpInternalRight>
                    <GelHeading1
                      style={{
                        color: getGelTokens().global.themeColorTextInverse,
                        fontSize: getGelTokens().global.fontSizeDisplayLarge,
                      }}
                    >
                      Claims
                    </GelHeading1>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <GelHeading1
                      style={{
                        color: getGelTokens().global.themeColorTextInverse,
                        fontSize: getGelTokens().global.fontSizeDisplayLarge,
                      }}
                    >
                      Lodgement
                    </GelHeading1>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <GelHeading1
                      style={{
                        color: getGelTokens().global.themeColorTextInverse,
                        fontSize: getGelTokens().global.fontSizeDisplayLarge,
                      }}
                    >
                      Portal
                    </GelHeading1>
                  </SignUpInternalRight>
                </Child>
              </GelColumnLayout>
            </GelForm>
          </SignUpDiv>
        </GelPageContent>
      </GelPageContentContainer>
      <GelPageFooter>
        <Footer />
      </GelPageFooter>
    </GelPageLayout>
  );
};

const Child = styled.div(() => ({
  height: 550,
  width: "100%",
}));

const SignUpDiv = styled.div(() => ({
  paddingTop: getGelTokens().global.sizeBaseX20,
  paddingBottom: getGelTokens().global.sizeBaseX20,
  minHeight: "90%",
  alignItems: "center",
  marginRight: "0px",
  paddingRight: "0px",
}));

const SignUpInternal = styled.div(() => ({
  minHeight: "100%",
  alignItems: "center",
  paddingTop: getGelTokens().global.sizeBaseX10,
  paddingBottom: getGelTokens().global.sizeBaseX10,
  paddingLeft:
    getGelTokens().global.sizeBaseX20 +
    getGelTokens().global.sizeBaseX20 +
    getGelTokens().global.sizeBaseX20,
  paddingRight:
    getGelTokens().global.sizeBaseX20 + getGelTokens().global.sizeBaseX20,
}));

const SignUpInternalRight = styled.div(() => ({
  paddingTop: getGelTokens().global.sizeBaseX20,
  paddingBottom: getGelTokens().global.sizeBaseX5,
  paddingLeft: getGelTokens().global.sizeBaseX5,
  minHeight: "100%",
  alignItems: "center",
  backgroundColor: getGelTokens().global.themeColorBackgroundInverse,
}));

const SignContainer = styled.div(() => ({
  padding: getGelTokens().global.sizeBaseX10,
  height: "100%",
}));

// const Child = styled.div`
//   ${() =>
//     `
//     width: "100%";
//     height: "100%";
//   `}
// `;

export default TwoFactor;
