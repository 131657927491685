import {
  GelButton,
  GelHeading3,
  GelPageLayout,
  GelPageContent,
  GelPageHeader,
  GelSpinner,
  GelIcon,
  GelModal,
} from "@tal-gel/components";
import { useNavigate } from "react-router-dom";
import { getGelTokens } from "@tal-gel/theming";
import styled from "@emotion/styled";
import { useContext, useEffect, useState } from "react";
import Header from "../../components/layout/Header";
import { UserContext } from "../../common/usercontext/user.context";
import { User } from "../../models/User";
import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridOverlay } from "@mui/x-data-grid";

interface Row {
  id: string;
  fullName: string;
  userId: string;
  roleName: string;
  branchName: string;
  status: string;
}

const ManageUsers = () => {
  const navigate = useNavigate();
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [openActionsMenu, setOpenActionsMenu] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Row | null>(null);
  const [users, setUsers] = useState<User[] | null>(null);
  const [loading, setLoading] = useState(false);
  const { contextData } = useContext(UserContext);

  const createUser = () => {
    if (contextData.userType === "DB69DAA7-224D-4D81-8ECC-F9DFD5A0A4B7")
      navigate("/createUser");
  };

  const editOrganisation = () => {
    if (contextData.userType === "DB69DAA7-224D-4D81-8ECC-F9DFD5A0A4B7")
      navigate("/organisation/" + contextData.organisation);
  };

  const backToDashboard = () => {
    navigate("/");
  };

  useEffect(() => {
    const getRoleId = async () => {
      if (contextData.userType !== "DB69DAA7-224D-4D81-8ECC-F9DFD5A0A4B7")
        navigate("/notAllowed");
    };

    getRoleId();
  }, []);

  const loadUsersByOrganisationId = async () => {
    setLoading(true);
    try {
      const organisation = contextData.organisation;
      console.log(contextData.userId);
      if (!organisation) return;

      const userId = contextData.userId;
      if (contextData.userType !== "DB69DAA7-224D-4D81-8ECC-F9DFD5A0A4B7")
        navigate("/notAllowed");
      let fetchedUsers: User[] | null = null;
      if (organisation) {
        const variables = {
          organisationId: organisation,
        };
        const usersQuery = `
              query {
                usersByOrganisationId(organisationId: "${organisation}") {
                  userId,
                  roleName,
                  branchName,
                  activated,
                  firstName,
                  lastName
                }
        }`;
        const usersResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
          {
            query: usersQuery,
            variables,
          }
        );
        fetchedUsers = usersResponse.data.data.usersByOrganisationId;
      }

      setUsers(fetchedUsers);
    } catch {
      navigate("/error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUsersByOrganisationId();
  }, []);

  const handleEdit = (userId: string) => {
    navigate(`/createUser/${userId}`);
  };

  const activateUserQuery = `
    mutation ActivateUser($userId: String!) {
      activateUser(userId: $userId)
    }`;

  const handleActivate = async (userId: string) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/graphql/`, {
        query: activateUserQuery,
        variables: {
          userId: userId,
        },
      });
    } catch {
    } finally {
      setOpenActionsMenu(false);
      setSelectedRow(null);
      loadUsersByOrganisationId();
    }
  };

  const deActivateUserQuery = `
    mutation deactivateUser($userId: String!) {
      deactivateUser(userId: $userId)
    }`;

  const handleDeactivate = async (userId: string) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/graphql/`, {
        query: deActivateUserQuery,
        variables: {
          userId: userId,
        },
      });
    } catch {
    } finally {
      setOpenActionsMenu(false);
      setSelectedRow(null);
      loadUsersByOrganisationId();
    }
  };

  const openMenu = (event: React.MouseEvent, row: Row) => {
    event.stopPropagation();
    setOpenActionsMenu(true);
    setMenuPosition({
      x: event.clientX,
      y: event.clientY - 50,
    });
    setSelectedRow(row);
  };

  const closeMenu = () => {
    setOpenActionsMenu(false);
    setSelectedRow(null);
  };

  const showActionsMenu = () => {
    if (!selectedRow) return null;

    return (
      <GelModal
        width="sm"
        open={openActionsMenu}
        onClose={closeMenu}
        style={{
          position: "absolute",
          top: menuPosition.y,
          left: menuPosition.x + 10,
        }}
      >
        <div>
          <GelButton
            primary
            small
            onClick={() => handleEdit(selectedRow.userId)}
          >
            Edit
          </GelButton>
          <br />
          {selectedRow.status === "Active" ? (
            <GelButton
              primary
              small
              onClick={() => handleDeactivate(selectedRow.userId)}
            >
              Deactivate
            </GelButton>
          ) : (
            <GelButton
              primary
              small
              onClick={() => handleActivate(selectedRow.userId)}
            >
              Activate
            </GelButton>
          )}
        </div>
      </GelModal>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Name",
      minWidth: 300,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      renderCell: (params: any) => (
        <div>
          {params.row["name"] && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  fontFamily: "Pluto Sans",
                }}
              >
                {params.row["name"]}
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      field: "branchName",
      headerName: "Group",
      minWidth: 280,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      renderCell: (params: any) => (
        <div
          style={{
            fontFamily: "Pluto Sans",
          }}
        >
          {params.row["branchName"]}
        </div>
      ),
    },
    {
      field: "roleName",
      headerName: "Role",
      minWidth: 280,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 280,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "actions",
      headerName: "",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            data-testid={params.row["name"]}
            onClick={(event) => openMenu(event, params.row)}
          >
            <GelIcon
              name="MoreVert"
              width={getGelTokens().global.sizeBaseUnit * 4}
            />
          </div>
        );
      },
    },
  ];

  function CustomNoRowsOverlay() {
    if (loading) return null;

    return (
      <GridOverlay>
        <div
          style={{
            textAlign: "center",
            fontFamily: "Pluto Sans",
            fontWeight: 700,
          }}
        >
          <div style={{ paddingBottom: "10px" }}>There are no users yet.</div>
          <div>Click on 'Create User' to create one.</div>
        </div>
      </GridOverlay>
    );
  }

  const displayUsers = users?.map((user) => {
    const fullName = `${user.firstName} ${user.lastName}`;

    return {
      id: user.userId,
      name: fullName,
      roleName: user.roleName,
      branchName: user.branchName,
      userId: user.userId,
      status: user.activated ? "Active" : "Deactivated",
      Actions: "Actions",
    };
  });

  return (
    <>
      (
      <GelPageLayout>
        <GelPageHeader>
          <Header />
        </GelPageHeader>
        <GelPageContent>
          <StyledUserContainer>
            <GelHeading3>Manage Users</GelHeading3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <div style={{ display: "flex" }}>
                <GelButton
                  style={{ top: "40px", marginRight: "20px" }}
                  primary
                  large
                  onClick={createUser}
                >
                  Create a new user
                </GelButton>
                <GelButton
                  style={{ top: "40px", marginRight: "20px" }}
                  secondary
                  large
                  onClick={editOrganisation}
                >
                  Edit Organisation
                </GelButton>
              </div>
              <GelButton
                style={{ top: "40px" }}
                secondary
                large
                onClick={backToDashboard}
              >
                Home
              </GelButton>
            </div>

            {loading && <GelSpinner large overlay />}
            <Box
              sx={{
                paddingTop: "90px",
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#EAECED",
                  fontFamily: "Pluto Sans",
                  fontWeight: 700,
                },
              }}
            >
              <DataGrid
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                disableRowSelectionOnClick
                rows={displayUsers || []}
                columns={columns}
                autoHeight={true}
                getRowHeight={() => "auto"}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 30,
                    },
                  },
                }}
                // onCellClick={(params, event) => {
                //   if (params.field === "Actions") {
                //     handleMenuClick(event);
                //   }
                // }}
              />
            </Box>
            {showActionsMenu()}
          </StyledUserContainer>
        </GelPageContent>
      </GelPageLayout>
      )
    </>
  );
};

const StyledUserContainer = styled.div`
  ${() =>
    `
    float: left;
    margin-left:  202px;
    margin-top:  40px;
  `}
`;

export default ManageUsers;
